.documentChatPannelMain
{
    position: absolute;
    top: 100%;
    width: 280px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 15px;
    z-index: 999;
    border-radius: 10px;
    max-height: 250px;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
}

.chatInputMain
{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}
.chatInputForm
{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.chatInputForm > input
{
    height: 33px;
    width: 100%;
    border: 1px solid #3c404369;
    border-radius: 6px;
    outline: none;
    text-indent: 10px;

}
.chatInputForm >input:focus
{
    outline: 1px solid #3C4043;
}
.chatInputForm > button
{
    width: 40%;
    height: 36px;
    border-radius: 22px;
    background-color: #E4E4E4;
    color: black;
    border: none;
    outline: none;
    cursor: pointer;
}
.chatInputHeader
{
    display: flex;
    gap: 10px;
    align-items: center;
}
.chatInputHeader > p{
font-size: 16px;

}

.documentChatRowsMain
{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-radius: 14px 14px 0 0;
    
}

.documentChatRow
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 0;
    border-bottom: 1px solid #B9B9B9;
}
.documentChatRowHeader
{
    display: flex;
    gap: 10px;


}
.documentChatRowInfo
{
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
}
.documentChatRowInfo > p
{
    font-size: 14px;
    color: black;
}
.documentChatRowInfo > span
{
    color: #3C4043;
    font-size: 12px;
}
.documentChatRowChat
{
    font-size: 15px;
    text-align: left;
}