.modalContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    box-shadow: 24;
    padding: 30px 30px;
    border-radius: 4px;
    width: 25vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}

.formContainer
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;
}
.contentWrapper
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contentWrapper>h2
{
   font-size: 20px;
   font-weight: 600px;
   word-wrap: break-word;
   text-wrap: balance;
   color: #353535;
}
.contentWrapper>p
{
   font-size: 15px;
   font-weight: 400px;
   word-wrap: break-word;
   text-wrap: balance;
    color: #2F2E2E;
}

.buttonWrapper
{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.buttonWrapper > button
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    min-width: 100px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    box-shadow: none;
    transition: all 0.3s ease-in-out;

}

.buttonWrapper > button:hover
{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}