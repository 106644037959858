.Container
{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    gap: 20px;
    background-color: white;
   
    padding: 20px 30px;
}

.mainContainer
{
    width: 100%;

}

.inputGroup
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputRow
{
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.inputRow>label
{
    font-size: 14px;
    color: #585858;

}

.valueContainer
{
    width: 100%;
    height: 45px;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.valueContainer>span
{
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
}

.dropdownBackDrop
{
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.096);
    position: fixed;
    inset: 0;

}
.selectorDropDownWrapper
{
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 505;
    border-radius: 10px;
    max-height: 400px;
    position: absolute;
    top: calc( 100% + 10px);
    width: 100%

    
}

.selectorSearchBar
{
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
}
.selectorSearchBar>input
{
    width: 100%;
    height: 40px;
    padding: 0 10px;
}

.selectorList
{
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100%;
    overflow: auto;
}

.selectorList>li
{
    padding: 10px;
}

.selectorList>li:hover
{
    background-color: #16459d42;
}

.upBtn
{
    padding: 0 20px;
    height: 40px;
    background-color: #16459d;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
}    