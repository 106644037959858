.navWrapper
{
    height: calc(100vh - 70px);
    display: flex;
    overflow: hidden;
    flex-direction: column;
   
}




.navMain {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    
    overflow: auto;
  
}


.navMain::-webkit-scrollbar {
    width: 5px;
}

.navMain::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.navMain::-webkit-scrollbar-thumb {
    background: #888;
}

.navMain::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.navItemBubble {
    width: 34px;
    height: 24px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F4F7;
    font-size: 12px;
    font-weight: 500;
    color: #344054;
}

.planMain {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.planText>h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #131313;
}

.planText>p {
    color: #667085;
    font-size: 10px;

}


.planThreeDotButton button {
    width: 32px;
    height: 32px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
    color: #131313;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
}

.planThreeDotButton >button:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 8px;
    background-color: white;
}

.planButtonExpand {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: absolute;
    bottom: 99%;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 6px;
    padding: 10px 0;
    transition: all 0.2s ease-in-out;
}

.planBox
{
   
    
    /* z-index: 5555; */
    background-color: white;
}

