.appTable1 {
    width: 100%;
}

.appTable1 table {
    width: 100%;
}

.appTable1 table .appTheader {
    width: 100%;
    background-color: #D2D2D2;
    
}

.appTable1 table .appTheader 
>th {
    width: 10%;
    height: 40px;
    color: #141313;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    
}

.appTable1 table .tbody td {
    width: 10%;
    height: 40px;
    color: #141313;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.appTable1 svg {
    color: #44475C;
}

/* --------------------header---------------------------- */

.appTable1 table .appTheader th:nth-child(1) {
    width: 15%;
    text-indent: 10px;
}

.appTable1 table .appTheader th:nth-child(2) {
    width: 15%;
  
}

.appTable1 table .appTheader th:nth-child(3) {
    width: 15%;
}

.appTable1 table .appTheader th:nth-child(4) {
    width: 10%;
}
.appTable1 table .appTheader th:nth-child(5) {
    width: 15%;
}
.appTable1 table .appTheader th:nth-child(6) {
    width: 10%;
}
.appTable1 table .appTheader th:nth-child(7) {
    width: 10%;
}

/* ---------------------body----------------------------- */

.appTable1 table .tbody td:nth-child(1) {
    width: 15%;
    text-indent: 10px;
}

.appTable1 table .tbody td:nth-child(2) {
    width: 15%;
}

.appTable1 table .tbody td:nth-child(3) {
    width: 15%;
}

.appTable1 table .tbody td:nth-child(4) {
    width: 10%;
}
.appTable1 table .tbody td:nth-child(5) {
    width: 15%;
}
.appTable1 table .tbody td:nth-child(6) {
    width: 10%;
}
.appTable1 table .tbody td:nth-child(7) {
    width: 10%;
}

/* -------------------------------------------------------- */

.appTable1 table .appTheader th {
    background-color: #fafafa;
    text-align: start;
    /* padding-left: 15px; */
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.2px;
    
}

.appTable1 table .appTheader {
    border: 0.5px solid #bcbcbc;

}

.appTable1 table .tbody {
    border: 0.5px solid #bcbcbc;
}

.appTable1 table .tbody td {
    text-align: start;
    /* padding-left: 15px; */
    color: #141313;
    font-size: 12px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.2px;
}


/* -------------------------------------- */

.PeofileSendBy {
    display: flex;
    gap: 8px;
    align-items: center;
    
}

.PeofileSendBy > p {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background-color: #00b4d8;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


 svg {
    color: #323131;
}

 svg:hover {
    color: #16469d;
}

/* ------------------------------------------------------------------------- */



/* ************************************************************************** */
.SortingDate {
    position: relative;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.SortingDate p {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.2px;

}

.SortingDate span {
    display: flex;
    align-items: center;

}


.SortingDate>ul {
    position: absolute;
    top: 110%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50px;
    right: -20px;
    z-index: 555;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 6px;
    background-color: #fff;
}

.SortingDate>ul>li {
    padding: 10px 15px;
    border-bottom: 1px solid rgb(178, 176, 176);
    width: 100%;
    text-align: start;
}

.SortingDate>ul>li:nth-child(4) {
    border-bottom: none;
    width: 100%;
}

.SortingDate>ul>li:hover {
    background-color: #e8e7e7;

}

.jjjjj svg {
    transform: rotateY(180deg)
}


.SortingDate svg {
    color: #323131;
}

.SortingDate svg:hover {
    color: #16469d;
}




/* ---------------------------------------- */


.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(46, 46, 46, 0.077);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.popupContent button {
    margin-top: 10px;
}



.massegesSlide {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: white;

    box-shadow: 24;
    padding: 20px 30px;

    border-radius: 4px;
    width: 28vw;
    height: 40vw;
    min-width: 300px;
    animation: slideUp 1.5s forwards;
    outline: none;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes slideUp {
    0% {
        left: 100%;
        top: 50%;
    }

    50% {
        left: 85%;
        top: 50%;
    }

    100% {
        left: 85%;
        top: 50%;

    }


}



/* -------------------------------New Menu Bar------------------------------------------ */
.actionTd{
    display: flex;
    width: 100%;
    justify-content:flex-start;
    height: 100%;
    align-items: center;
    gap: 35px;
}
.actionTd span
{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.actionTdMore
{
    position: absolute;
    top: 100%;
    width: 250px;
    right: 0;
    /* right: -30px; */

    background-color: white;
    display: flex;
   
    flex-direction: column;
    list-style: none;
    z-index: 150;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.actionTdMore >li
{
padding: 15px 20px;
display: flex;
gap: 2px;
font-size: 16px;
}
.actionTdMore li:hover
{
    background-color: rgb(213, 225, 248);
}
.dropdownNames{
    display: flex;
    padding-left: 10px;
    gap: 20px;
}
.dropdownNames>span{
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: red; */
}
/* ______________________________ */
/* Add these styles for your tabs */
.tabContainer {
    display: flex;
    /* justify-content: center; */
    margin-top: 20px;
  }
  
  .tabContainer button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #333; /* Text color for inactive tabs */
    transition: color 0.3s ease-in-out;
  }
  
  .tabContainer button.active {
    color: #007bff; /* Text color for the active tab */
    border-bottom: 2px solid #007bff; /* Underline for the active tab */
    font-weight: bold; /* Optionally, make the active tab bold */
  }
  .emailSelection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.emailSelection>label {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #474343;
}
.emailSelection>label>input{
    width: 50px;
}
/* ************************************************** */
.emailCheckbox {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
  }
  .emailCheckbox > label{
    font-size: 14px;
  }
  
  label {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  
  /* Style the checked checkbox */
  .Mui-checked {
    color: #1976d2; /* Customize the checked color */
  }
  .emailSelectionDropdown {
    max-height: 150px;
    overflow-y: auto;
    /* position: absolute; */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* padding: 4px; */
    z-index: 1;
    width: 100%;
  }
  
  .emailSelectionDropdown label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 4px ;
    /* user-select: none; */
  }
  
  .emailSelectionDropdown label:hover {
    background-color: #f5f5f5;
  }
  .inputRow {
    position: relative; /* Add this to make the dropdown icon positioning relative to the label */
  }
  
  .inputRow>label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    /* user-select: none; */
    border: 1px solid #ccc; 
    border-radius: 4px;
    width: 100%; /* Set width to 100% */
    box-sizing: border-box;
  }
  
  /* .inputRow label:hover {
    background-color: #f5f5f5;
  } */
  
  .inputRow .emailSelectionDropdown {
    max-height: 150px;
    overflow-y: auto;
    /* position: absolute; */
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none; 
    border-radius: 0 0 4px 4px; 
    padding: 4px;
    z-index: 1;
    width: 100%; 
    box-sizing: border-box;
  }
  
  .inputRow .emailSelectionDropdown label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    /* user-select: none; */
  }
  
  .inputRow .emailSelectionDropdown label:hover {
    background-color: #f5f5f5;
  }

/* Remove focus outline from checkbox */
.MuiCheckbox-root:focus {
    outline: none;
}
