.modalContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 500px; */
    background-color: white;
    box-shadow: 24;
    padding: 30px 30px;
    border-radius: 4px;
    width: 25vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 90vh;
    overflow-y: auto;


}

.modalContainer::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}


.formContainer
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    overflow-y: auto;
}

.modalHeader
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.modalHeader>h3
{
    font-size: 24px;
    font-weight: 400;
    color: #1F1F1F;
    display: flex;
    align-items: center;
    gap: 5px;
}
.modalHeader>h3>span
{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>h3>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}

.modalHeader>span
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}


.formGroup
{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    position: relative;
}
.formGroup>label
{
   font-size: 14px;
   font-weight: 500;
   color: #606778;
   line-height: 25px;
}

.formGroup>select
{
    width: 100%;
   
   height: 36px;
    border: 1px solid #CAD0DB;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}
.formGroup>textarea
{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 3lh;
    max-height: 5lh;
    border: 1px solid #CAD0DB;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}

.formGroup>textarea:focus, .formGroup>select:focus
{
    border: 1px solid #1F1F1F;
}

.daysSelector {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #CAD0DB;

    color: #12141A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
}

.daysSelectorbtns {
    display: flex;
    gap: 5px;
    height: 100%;
    align-items: center;
    padding: 5px 0;
}

.daysSelectorbtns>span {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
}

.daysSelectorbtns>span:hover .daysSelector
{
    border: 1px solid #232324;
}

.daysSelectorbtns>span:nth-child(1)
{
    background-color: rgb(246, 250, 255);
    color: #16469D;
}
.daysSelectorbtns>span:nth-child(2)
{
    background-color: #16469D;
    color: white;
}

.orDivider {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 30px;
    position: relative;

}

.orDivider>span {

    z-index: 999;
    background-color: white;
    padding: 5px;
    font-size: 12px;
    color: #d2d2d2;

}

.orDivider::after {
    content: "";
    height: 1px;
    background-color: #d2d2d2;
    width: 100%;
    position: absolute;
    z-index: 992;
}