.mainContact {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1698404943/Frame_427320614_nm16hy.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 10px;
}

.startContact {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}

.startContact form {
    width: 100%;
    max-width: 1300px;
    color: #000;
    font-size: 40px;
    background-color: #fff;
    font-weight: 700;
    padding: 4%;
    border-radius: 8px;
    gap: 25px;
    display: flex;
    flex-direction: column;
}

.startContact form h1 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
}

.startContact form p {
    color: #475569;
    font-size: 20px;
    font-weight: 400;
}

.startContact form p span {
    color: #16469D;
    font-size: 20px;
    font-weight: 400;
}

.formContact {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    margin: 4% 0 0 0;
}

.formContact>div {
    flex-grow: 1;
    flex-basis: 400px;
}
.formContact>div label {
    font-size: 16px;

}
.formContact>div label span {
    color: #b20702;

}

.formContact>div>input {
    height: 40px;
}

.formContact>div>textarea {
    max-width: 100%;
    min-width: 100%;
    min-height: 5lh;
    padding: 10px 2px;
}

.startContact form button {
    max-width: 100%;
    min-width: 100%;
    background-color: #16469D;
    color: #fff;
    height: 52px;
    text-transform: capitalize;
    font-size: 18px;
}

@media screen and (max-width:786px){
    .startContact form h1 {
        font-size: 30px;
    }
    
    .startContact form p {
        font-size: 16px;
    }
    
    .startContact form p span {
        font-size: 16px;
    }
    .startContact form button {
        font-size: 16px;
    }
    
}