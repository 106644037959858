.mainImgInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 5% 0;
    padding: 10px;
}

.mainImgInfo .imgDivStart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
    padding-top: 80px;

    min-height: 500px;
    max-height: 650px;
    max-width: 1300px;
    background-color: #16469d;
    border-radius: 20px;

}

.imgDivStart .procImageconnet {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 15px 35px;
}
.imgDivStart .procImagepp {
    width: 50%;
    /* height: 50vh; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}

.procImagepp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;


}

.procImageconnet h1 {
    font-size: 64px;
    font-weight: 500;
    color: #fff;
}

.procImageconnet p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #fff;
}

.procImageconnet ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.procImageconnet ul li {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    list-style: none;
    flex-grow: 1;
    flex-basis: 280px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.procImageconnet ul li svg {
    border-radius: 50%;
    background-color: #fff;
    color: #456bb1;
}


@media screen and (max-width:768px) {
    .imgDivStart{
        display: flex;
        flex-direction: column;
    }
    .imgDivStart .procImageconnet{
       width: 100%;
    }
    .imgDivStart .procImagepp{
       width: 100%;
       height: 30vh;
    }
  
    
    .procImageconnet h1 {
        font-size: 30px;
    }
    
    .procImageconnet p {
        font-size: 16px;
        line-height: 20px;

    }


}