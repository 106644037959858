
.documentsTabMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0px;
}

.documentRow {
    display: flex;
    width: 100%;
    height: 48px;
    border: 1px solid #D2D2D2;

}

.documentRow>p {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    font-size: 14px;
    font-weight: 500;

}

.documentRow>div {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.documentRow>div>p {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0px 15px;

}

.documentRow>div>div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.documentRow>div>div>a {
    width: 25px;
    height: 25px;
    color: #1b1b1b;

}

.documentRow>div>div>a:hover {
    color: #1b1b1b;
}

.accordianDocumentabHeader {
    width: 100%;
    align-items: center;
    height: 40px;
    display: flex;
    border: 1px solid #D2D2D2;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}


.accordianDocumentabHeader>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.DocumentTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #D2D2D2;
}

.DocumentTableHead {
    height: 48px;
    background-color: #F6F6F6;
    border: 1px solid #D2D2D2;
}

.DocumentTableRow {
    height: 48px;
    border: 1px solid #D2D2D2;
}

.DocumentTableRow td:nth-child(1) {
    width: 50%;
}

.DocumentTableRow td:nth-child(2) {
    width: 16%;
    text-align: center;

}

.DocumentTableRow td:nth-child(3) {
    width: 16%;
    text-align: center;

}

.DocumentTableRow td:nth-child(4) {
    width: auto;
    padding-right: 10px;
}

.DocumentTableRow th,
.DocumentTableRow td {
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;


}

.DocumentTableRow th {
    font-weight: 500;
}

.documentIconRow {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: flex-end;
}

.documentIconRow>a {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.documentMoreDots {
    position: relative;
}

.documentMoreDots>ul {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 220px;
    list-style: none;
    z-index: 500;

}

.documentMoreDots>ul>li {
    padding: 10px 15px;
    font-size: 15px;
    color: #1b1b1b;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}