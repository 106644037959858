

.productSelectors
{
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    gap: 10px;
    width: 100%;

}

.productSelectorColumn
{
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
}
.productSelectorColumn > label
{
    font-size: 12px;
    line-height: 17px;
    color: #252628;
}

.productSelector
{
    width: 100%;
    position: relative;
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    color: #7B8499;
    /* overflow-Y: auto; */

}
.productSelector > p
{
font-size: 14px;
color: #252628;
display: flex;
width: 100%;
align-items: center;
justify-content: space-between;
}

.productDropDownDiv
{
    position: absolute;
    display: flex;
    top: 108%;
    z-index: 900000;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    padding-bottom: 10px;
    /* right: 100%; */

}
.productSearchBar
{
  
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.productSearchBar > input
{
    width: 100%;
    height: 35px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-indent: 10px;
    border-radius: 4px;
}

.productDropDownList
{
    list-style: none;
    flex-direction: column;
    display: flex;
    width: 100%;
    overflow-y: auto;
  

   
}

.productDropDownList > li
{
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    color: #252628;
}

.productDropDownList > li:hover
{
    background-color: rgba(44, 44, 212, 0.205);
}

.productSelectbtn
{
    display: flex;
    gap: 10px;
    
}

.proSelectPrimaryBtn
{
    width: 13vw;
    height: 40px;
    border: none;
    background-color: #16469D;
    border-radius: 7px;
    font-size: 14px;
    color: white;
    padding: 10px;

}

.proSelectSecondaryBtn
{
    color: #16469D;
    border-radius: 7px;
    font-size: 14px;
   
    padding: 10px;
    border: none;
    background-color: transparent;
    font-weight: 600;
}

.succesSVg svg{
    color: green !important;
}

.backButtonCSS:hover{
    box-shadow: 5px 10px 18px #888888;
}




/* Dropdown CSS */
.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    
    /* border: 1px solid black; */
    
}

.dropdownContainer input {

    color: #7B8499;
    font-weight: 500;
    margin-top: 2% !important;
}

.dropdown {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    color: #7B8499;
    overflow-Y: auto;
    font-weight: 550;
    font-size: 13px;
    text-indent: 5px;
    
}



.dropdown.open  {

    border: 1px solid black;
    outline: none;
}

.arrowIcon {
    transition: transform 0.3s ease;

}

.open .arrowIcon {
    transform: rotate(180deg);
}


.selectedCategories
{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}


.selectedCategory {
    display: inline-flex;
    align-items: center;
    margin-right: 1px;
    margin-top: 1px;
    padding: 4px 8px;
    background-color: #d6e8ff;
    color: #12141A;
    border-radius: 20px;
    font-size: 12px;
}
.closeIcon {
    font-size: 0.8rem !important;
    margin-left: 6px;
    cursor: pointer;
}

.dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    border-radius: 5px;
}

.dropdownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    width: 100%;
}

.dropdownItem >span
{
    font-size: 14px;
}

.dropdownItem > input
{
    all: revert;
}


.redirectRegisterToast {
    top: 20% !important;
}
