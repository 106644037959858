.questionsTabMain
{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.questionsTable
{
    border: 1px solid #d2d2d2;
    border-collapse: collapse;
}

.questionsTableRow > th ,.questionsTableRow > td 
{
    border: 1px solid #d2d2d2;
   text-align: left;
   min-width: max-content;
  
}

.questionsTableRow > th
{
height: 36px;
background-color: #FAFAFA;
position: sticky;
top: 0;
outline: 1px solid #d2d2d2;
color: #141313;
font-size: 12px;
font-weight: 500;
line-height: 17px;
padding:5px ;
text-align: left;

}
.questionsTableRow > td 
{
    height: 40px;

font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #656363;
}

/* .questionsTableRow > th:nth-child(1) ,
.questionsTableRow > td:nth-child(1)
{
width: 3%;
padding: 5px;
}
.questionsTableRow > th:nth-child(2) ,
.questionsTableRow > td:nth-child(2)
{
width: 20%;
}
.questionsTableRow > th:nth-child(3) ,
.questionsTableRow > td:nth-child(3)
{
width: 20%;
}
.questionsTableRow > th:nth-child(4) ,
.questionsTableRow > td:nth-child(4)
{
width: 20%;
}
.questionsTableRow > th:nth-child(5) ,
.questionsTableRow > td:nth-child(5)
{
width: 20%;
}
.questionsTableRow > th:nth-child(6) ,
.questionsTableRow > td:nth-child(6)
{
width: 17%;

} */

.questionsTableRow > td:nth-child(6)>span
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.questiontableFooter
{
    width: 100%;
    border: 1px solid #d2d2d2;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px;
    gap: 4px;
    cursor: pointer;

}

.questiontableFooter > span
{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.questiontableFooter > span>svg
{
    width: 100%;
    height: 100%;
}

.questionRowTextarea
{
    width: 100%;
    min-height: 40px;
    border-radius: 0;
    border: none;
    background-color: transparent;
    padding: 10px 5px;
    form-sizing:content;
   
    min-width: 100%;
    max-width: 100%;
    max-height: 8lh;
}
.questionRowTextarea:focus, .questionRowInput:focus
{
    outline: none;
}

.questionRowInput
{
    width: 100%;
    min-height: 40px;
    border-radius: 0;
    border: none;
    background-color: transparent;
    padding: 5px 0 ;
    text-indent: 5px;
    color: black;
}

.questionRowSelect
{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}





