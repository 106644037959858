.chatRowContainer
{
display: flex;
width: 100%;
flex-grow: 1;
flex-direction: column;
overflow-y: auto;
padding: 10px 0;
}

.chatRowMain {
    display: flex;
    width: 100%;
    padding: 15px 20px;
    border-bottom: 0.5px solid #D2D2D2;
    gap: 10px;
   
}

.chatProfile {
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
}

.chatProfile>span {
    background-color: #7B9EDD;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 14px;
    color: white;
}

.chatDescription {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.chatNameRow {
    display: flex;
    align-items: center;
    gap: 10px;

}

.chatNameRow > h4 {

    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
   color: #7B9EDD;

}
.chatNameRow > span
{
font-size: 12px;
color: #A1A1A1;
}

.chatRowChat
{
    width: 100%;
    display: flex;
    align-items: center;

}
.chatRowChat p
{

font-size: 14px;
font-weight: 500;
line-height: 16px;


}