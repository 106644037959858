.taskModalWrapper {
    width: 100%;
    height: 100%;
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;


}

.taskModal {
    width: 100%;
    max-width: 1300px;
    height: 640px;
    max-height: 90vh;
    display: flex;
    background-color: white;
    /* padding: 15px 15px; */

}

.taskInfoWrapper {
    width: 70%;
    /* height: 100%; */
    flex-direction: column;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    padding: 15px 0px 15px 15px;

}



.taskInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: #ffff;
    padding-right: 20px;
    height: 90%;
}

.chattComp {
    width: 30%;

}

.taskNav {
    display: flex;
    /* flex-direction: column; */
    gap: 30px;
    height: 45px !important;
    min-height: 45px;
    border-bottom: 2px solid #ccc;
    /* padding: 20px 10px; */
    align-items: center;


}

.taskNav>h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3F3F3F;


    /* box-shadow: '100px 80px 100px 50px ',   */
}

.createTask {
    /* width: 100%; */
    font-weight: 600;

}


.taskNav>h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #3F3F3F;

}

.taskHeading {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.taskHeading>input {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3F3F3F;

    border: 1px solid #D7D7D7;
    /* background-color: #f6f4f4; */
    border-radius: 4px;
    height: 40px;
    text-indent: 7px;

}

.taskHeading>textarea {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #757575;
    padding-top: 7px;
    padding-left: 7px;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    /* font-style: italic; */
    height: 60px;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    min-height: 4lh;
    max-height: 6lh;
}

.taskHeading>input:focus,
.taskHeading>textarea:focus {
    border: 1px solid transparent;
    outline: 1px solid #0000008f;
    /* box-shadow: 0px 0px 2px 2px #16469D; */
}


.taskDetailsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    overflow-y: auto;

}

.taskDetailsWrapper::-webkit-scrollbar {
    width: 6px;
    /* Width of the vertical scrollbar */
}

.taskDetailsWrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners for the thumb */
}

.taskDetailsWrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Color of the thumb on hover */
}

.taskDetailsWrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Color of the track */
}

.taskDetailsRow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.taskDetailsCol {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    padding: 0 12px;
    border: 1px solid #cccccc;
    border-top: none;
    border-right: none;


}

.taskDetailsCol:nth-child(2) {
    flex-grow: 1;
    border-right: 1px solid #cccccc;

}

.taskDetailsWrapper>div:nth-child(1)>div:nth-child(2){
   border-right: none;
}
.taskDetailsWrapper>div:nth-child(1)>div:nth-child(4){
   border-right: 1px solid #cccccc;
}
.taskDetailsWrapper>div:nth-child(1)>div
{
    border-top: 1px solid #cccccc;
  
}

.taskDetailsCol>span {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.taskDetailsCol>span>svg {
    height: 100%;
    width: 100%;
}

.taskDetailsCol>p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16.94px;
    color: #262525;
}

.taskDetailsCol>input[type="date"] {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #3F3F3F;
}

.taskDetailsCol>input[type="date"]:disabled {
    background-color: transparent;
    color: #727272;
}


.taskFileWrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}


.uploadButton {
    width: max-content;
    padding: 5px 10px;
    font-size: 12px;
    background-color: transparent;
    border-radius: 4px;
    color: #3F3F3F;
    border: 0.5px solid #3F3F3F;
    font-weight: 500;
    cursor: pointer;

}
.uploadButton:hover {
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;

}

.selectedFileItem {
    width: max-content;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #F6FAFF;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.selectedFileItem>p {
    font-size: 12px;
    font-weight: 400;
}
.selectedFileItem>span {
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #E2ECFF;
}
.selectedFileItem>span>svg {
    height: 90%;
    width: 90%;
    color: #164699;
    font-weight: 600;
}

.taskButton {
    width: max-content;
    padding: 5px 10px;
    font-size: 12px;
    
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: none;
}







.list {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;

}

.listforCalendar {
    height: 35px;
}

.list>li {
    padding: 5px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    gap: 10px;
}

.list>li>button {
    background-color: #EEEBEB;
    box-shadow: none;
    border-radius: 4px;
    text-transform: capitalize;

}

.list>li>button:hover {
    background-color: #EEEBEB;
    box-shadow: none;
    border-radius: 4px;

}

.uploadFilesModal {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dragAndDrop {
    background-color: #F6F6F6;
    border: 1px dashed #AFAFAF;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
}

/* .dragAndDrop{

} */

.saveDiscardButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.saveDiscardButtons>button:nth-child(1) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    padding: 10px 20px;
    background-color: #16469D;
    text-transform: capitalize;
    border-radius: 4px;
}





/* Third Modal Style  */
.thirdModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #ffff;
}

.thirdModal:hover {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #ffff;
}

.thirdModal>h5 {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;

    color: #262525;
}

.thirdModal>h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    color: #262525;
}

.fourthdModal>h5 {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;

    color: #262525;
}

.emailSelection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.emailSelection>label {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #474343;
}

.emailSelection>label>input {
    width: 50px;
}

/* ************************************************** */


.divider {
    display: flex;



}

.selectCustomStyle {
    padding: 0px;
    height: 20px;
    /* border: ; */
    /* background-color: red; */
}


.selectorDiv {
    display: flex;
    width: 100%;
    max-width: 250px;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.ulselector {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 110%;
    z-index: 555;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    max-width: 250px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.ulselector>li {
    padding: 10px 15px;
}

.ulselector>li:hover {
    background-color: #16459d4b;
}


/* *********************** */
.errorMessage {
    font-size: 12px;
    color: red;
    margin-top: -10px;
}

.formattedDate {
    display: flex;
    align-items: center;
    color: #6A6A6A;
    font-size: 14px;
    /* Adjust font size as needed */
}

.dateInput {
    display: flex;
    align-items: center;
}

.list1 {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    width: 100%;
}

.list1>li:nth-child(1) {
    padding: 13px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.list1>li:nth-child(1)>span {
    font-size: 14px;
}

.list1>li:nth-child(2) {
    padding: 5px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.listItem1 {
    display: flex;
    align-items: center;
    /* margin-right: 20px; */
}

.listItemText1 {
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
    color: #6A6A6A;
}

.selectCustomStyle1 {
    background-color: #F5F5F5;
    border-radius: 4px;
    /* padding: 10px; */
    max-height: 40px;
    font-size: 16px;
    color: #333;
    border: 1px solid #ccc;
}

.menuItem1 {
    display: flex;
    flex-direction: column;
}

.category1 {
    color: red;
    font-size: 12px;
}

.productName1 {
    font-size: 16px;
    margin-top: 5px;
}

.customSelect .MuiMenu-paper {
    box-shadow: none;
}

/* ------------------------------------------------------------- */
/* TaskPannel.module.css */

.productSelectContainer {
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    position: relative;
}

.productSearch {
    display: flex;
    align-items: center;
    padding: 8px;
}

.productSearch input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 6px;
    border-radius: 4px;
    margin-left: 8px;
}

.productList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: lightgray transparent;
}

.productItem {
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.productItem:hover {
    background-color: #f2f2f2;
}

.selectedProduct {
    background-color: #007bff;
    color: white;
}

.productInfo {
    display: flex;
    flex-direction: column;
}

.productCategory {
    font-size: 14px;
    color: #6A6A6A;
}

.productName {
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 4px;
}

/* ------------------------------------------------------------------ */

.hoveredDiv:hover {
    background-color: #F6F6F6;
    transition: 1s;
}

.hoveredDiv2:hover {
    background-color: #F6F6F6;
    transition: 1s;
}

.FileSelect {
    overflow-y: auto;
    max-height: 200px;
    scrollbar-width: thin;
    scrollbar-color: #cccccc #f0f0f0;
}

.FileSelect::-webkit-scrollbar {
    width: 10px;
}

.FileSelect::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.FileSelect::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;
}

.error-input {
    border: 1px solid red;
    /* Add red border for error input */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainTask {
    width: 100%;
    background-color: #fff;

}




/* ------------------------------------------------------------------ */

.hoveredDiv:hover {
    background-color: #F6F6F6;
    transition: 1s;
}

.hoveredDiv2:hover {
    background-color: #F6F6F6;
    transition: 1s;
}

.FileSelect {
    overflow-y: auto;
    max-height: 200px;
    scrollbar-width: thin;
    scrollbar-color: #cccccc #f0f0f0;
}

.FileSelect::-webkit-scrollbar {
    width: 10px;
}

.FileSelect::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.FileSelect::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;
}

.error-input {
    border: 1px solid red;
    /* Add red border for error input */
}



.AttachmentColumnRr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.AttachmentColumnRr>div {
    display: flex;
    flex-wrap: wrap;

}


.AttachmentColumnRr>div>div {
    /* flex: 1 0 80px; */
    min-width: 110px;
    flex-shrink: 0;
}



/* Datepicker css */

/*To hide icon*/
/* .dateInput::-webkit-calendar-picker-indicator {
    display: none;
  } */