
.specficationTabMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;

}

.richEditor {
    width: 100%;
    height: 100%;
    /* overflow-y: auto; */

}

.richEditor>div {
    width: 100%;
    height: 40vh;
}
.richEditor>div>div:nth-child(2)
{
    height: calc(100% - 50px);
    
}
.vendorRemarkCont
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;

}
.vendorRemarkCont>label
{   
    font-size: 18px;
    font-weight: 600;
}
.vendorRemarkCont>textarea
{
    width: 100%;
    height: 6lh;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #d2d2d2;
    outline: none;
    font-size: 14px;
}
.vendorRemarkCont>textarea:focus
{
    border: 1px solid #525252;
    
}
