.redirectRegisterMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
   
}

.redirectRegisterColumn {
    display: flex;
    height: calc(100vh - 80px);
    
    width: 100%;
    /* height: 100%; */
}
.redirectRegisterBox {
    display: flex;

    justify-content: flex-start;
    width: 50%;
    padding: 40px;
    width: max(500px,50%);
    flex-grow: 1;
}

.slider {
    width: 50%;
    height: 100%;
}


@media screen and (max-width:768px) {
    .redirectRegisterBox {
       
        width: 100%;
        padding: 20px;
        justify-content: center;
        /* height: 100%; */
    } 
    .slider {
       display: none;
    }
    
}




.redirectRegisterForm {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    gap: 30px;
    flex-direction: column;
}

.formHeading > h2
{
    font-size: 25px;
    font-weight: 700;
    color: #000000;
}
.formHeading > h5
{
font-size: 15px;
font-weight: 600;
color: #000000;
}




/* 
.formInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}


.redirectRegisterForm label {
    font-weight: 500;
    font-size: 12px;
    color: #404349;
} */

/* .formSubmit {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formSubmit button {
    background-color: #16469D;
} */

/* .formSubmit p {
    color: #7B8499;
    font-size: 11px;
    font-weight: 500;
}

.redirectRegisterForm p a {
    color: #12141A;
} */

/* Dropdown CSS */
.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    
    /* border: 1px solid black; */
    
}

.dropdownContainer input {

    color: #747775;
    font-weight: 500;
    margin-top: 2% !important;
}

.dropdown {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    color: #7B8499;
    overflow-Y: auto;
    font-weight: 550;
    font-size: 13px;
    text-indent: 5px;
    
}



.dropdown.open  {

    border: 1px solid black;
    outline: none;
}

.arrowIcon {
    transition: transform 0.3s ease;

}

.open .arrowIcon {
    transform: rotate(180deg);
}


.selectedCategories
{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}


.selectedCategory {
    display: inline-flex;
    align-items: center;
    margin-right: 1px;
    margin-top: 1px;
    padding: 4px 8px;
    background-color: #d6e8ff;
    color: #12141A;
    border-radius: 20px;
    font-size: 12px;
}
.closeIcon {
    font-size: 0.8rem !important;
    margin-left: 6px;
    cursor: pointer;
}

.dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    border-radius: 5px;
    z-index: 90008;
}

.dropdownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    width: 100%;
}

.dropdownItem >span
{
    font-size: 14px;
}

.dropdownItem > input
{
    all: revert;
}


.redirectRegisterToast {
    top: 20% !important;
}






/* @media (max-width: 998px) {

    .slider {
        display: none;
    }

    .redirectRegisterBox {
        width: 100%;
        align-items: center;
    }

}

@media (max-width: 550px) {

    .redirectRegisterForm {
        width: 100%;
    }

} */