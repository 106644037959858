.ErrorToastPosition {
    position: fixed;
    top: 8%;
    background-color: #FFBABA;
    border: 1px solid #D8000C;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 99999;
    border-radius: 5px;
}


.ErrorToastPosition.visible {
    opacity: 1;
}