.userTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
    overflow-y: auto;
    min-width: 1000px;
}

.userTableHeader {

    background-color: #FAFAFA;
    height: 36px;


}

.userTableHeader li {
    font-weight: 700;
    font-size: 12px !important;
}


.userTablerow {
    width: 100%;
    display: grid;
    list-style: none;

    border-bottom: 1px solid rgb(196, 196, 196);
    grid-template-columns: 1.4fr repeat(5, 1fr);
    padding: 0 10px;
}

.userTablerow li {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;

}

.userTablerow li p {
    font-size: 15px;
}

.roleTab {
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 5px;
    border-bottom: 1px solid rgb(196, 196, 196);

}

.roleTabHeader {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 5px 15px;
    gap: 10px;
    border-bottom: 1px solid rgb(196, 196, 196);
    cursor: pointer;
}

.roleTabHeader>svg {
    font-size: 20px;
    font-weight: 500;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.1s ease-in-out;
}

.roleTabHeader>h4 {
    font-size: 14px;
    font-weight: 500;
}

.roleTabHeader:hover>svg {
    background-color: rgba(128, 128, 128, 0.24);
}

.userTableEmail p {
    color: grey;
    font-size: 14px !important;

}

.userTableEmail>span {
    all: revert !important;
}

.userTableWorkspace {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.userTableWorkspace p {
    background-color: #E2E2E280;
    flex: 1 0 80px;
    padding: 0 5px;
    font-size: 12px !important;
    color: black;
}

.userTableActive {
    color: grey;

}

.userTableActive>div {
    display: flex;
    justify-content: center;
}

.userTableActive>div>svg {
    font-size: 16px;
}

.userTableAction i {
    color: #44475C;
}

.userTableAction i:hover {
    color: #81cff8;
}

.gray-background {
    background-color: #ccc;
    /* You can replace this with your desired gray color */
}

.VendorheaderStyleOrg {
    display: flex;
    width: 100%;
    background-color: #fafafa;
    height: 36px;
}

.VendorheaderStyleOrg li {
    display: flex;
    max-width: 300px;
    min-width: 300px;
    align-items: center;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    text-indent: 10px;


}

.StyleOrganisationVendor {
    display: flex;
    width: 100%;
    border: 1px solid #d2d2d2;
    justify-content: space-between;
}

.StyleOrganisationVendor>div {
    display: flex;
    padding: 5px 0px;
}

.StyleOrganisationVendor>div:nth-child(2) li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.StyleOrganisationVendor>div:nth-child(2) li:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;

}

.StyleOrganisationVendor li {
    list-style: none;
    color: #141313;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    display: flex;
    align-items: center;
    max-width: 300px;
    min-width: 300px;
    text-indent: 10px;
    
    
}
.requestButton{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
}

.requestButton button{
    text-transform: capitalize;
    font-size: 16px;   
   
}
.requestButton>button:hover{
    cursor: pointer;
}