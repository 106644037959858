.formContainer
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
}

.modalHeader
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.modalHeader>h3
{
    font-size: 24px;
    font-weight: 400;
    color: #1F1F1F;
}
.modalHeader>span
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}







.formGroup
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: relative;
}
.formGroup>label
{
   font-size: 14px;
   font-weight: 400;
   color: #1F1F1F;
   line-height: 25px;
}

.formGroup>input[type="text"]
{
    width: 100%;
    height: 40px;
    border: 1px solid #747775;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}

.formGroup>input[type="text"]:focus
{
    border: 1px solid #1F1F1F;
}
