.CompanyProfilePictureBox {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.companyprofileLastRow {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.companyprofileLastRow figcaption {
    font-size: 12px;
    color: #BDBDBD;
}

.companyprofileLastRow img {
    width: 200px;
    height: 70px;
    object-fit: contain;
}

.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 125px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-align: center;
    padding: 20px;
    font-size: 14px;
  }
  
  .dropzone p {
    margin: 0;
  }

  .dropzone .uploadPicInDropzone {
    font-size: 16px;
    color: rgb(7, 7, 87);
    text-decoration: underline;
    background-color: #f9f9f9;
    border: none;
    cursor: pointer;
  }

  .selectedCateDta {
    width: 90%;
    padding: 10px;
    border: 1px solid #BDBDBD;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    font-size: 12px;
    color: black;
    margin-bottom: 2px;
    background-color: #f8f8f8;
  }


@media (max-width: 1000px) {
    .profileMainRow {
        flex-direction: column-reverse;
        gap: 10px;
    }
    .ProfileInfoTable {
        width: 100%;
    }
    .CompanyProfilePictureBox{
        width: 100%;

    }
}
