.table1 {
    width: 100%;
}
.table1 .heading h1 {
    color: #16469d;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: 0.1px;
    margin-bottom: 2%;
}

.table1 table {
    width: 100%;
}

.table1 table .theader {
    width: 100%;
    background-color: #D2D2D2;
}

.table1 table .theader th {
    width: 10%;
    height: 40px;
    border: 1px solid #bcbcbc;
    color: #141313;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.table1 table .tbody td {
    width: 10%;
    height: 40px;
    border: 1px solid #bcbcbc;
    color: #141313;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.table1 svg {
    color: #44475C;
}


.theader th, .tbody td
{
min-width: 100px
}
.table1 table .theader th:nth-child(1) {
    width: 5%;
}

.table1 table .theader th:nth-child(2) {
    width: 25%;
}

.table1 table .tbody td:nth-child(1) {
    width: 5%;
}

.table1 table .tbody td:nth-child(2) {
    width: 25%;
    text-align: start;
    padding-left: 1%;
}


.table1 tbody tr,
.table1 tbody td {
    border: 1px solid gray;
    height: 40px;
    /* padding: 2%; */
}

.addTask {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-left: 1px solid #bcbcbc;
    border-right: 1px solid #bcbcbc;
    gap: 10px;
    padding-left: 1%;

}

.addTask p {
    color: #141313;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;

}

.table1 tbody .icons {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.table1 svg:hover {
    color: #16469d;
}

/* --------------------------------- */
.select-tab {
    position: relative;
    display: inline-block;
    font-family: Arial, sans-serif;
    cursor: pointer;
}

.selected-option {
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrow {
    margin-left: 8px;
    font-size: 12px;
}

.open .arrow.up {
    transform: rotate(180deg);
}

.options-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.option {
    padding: 8px 10px;
    cursor: pointer;
}

.option:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #f0f0f0;
}

.SelectBar {
    width: 100%;
    height: 100%;
}

.SelectBarStart {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
}

/* .SelectBarStart option {
    background-color: rgb(223, 255, 231);
    padding: 20px !important;
    border-radius: none !important;
    font-size: 15px;
    border-bottom: 2px solid black;
} */

.addAssignMain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;



}

.addAssign {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding-left: 20%;
    background-color:transparent;

}

.addAssign>div {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: 3px solid #fff;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addAssign>div:hover {
    transform: translateY(-5px);

}

/* ------------------------------ */
.addAssign .name1 {
    object-fit: fill;
    /* position: relative; */
    /* right: 8%; */
    margin-left: -10px;
    z-index: 1;
    animation-duration: 2s;
    color: #fff;

}

/* 
.addAssign .name2 {
    position: relative;
    right: 16%;
    z-index: 2;
    animation-duration: 2s;
    color: #fff;
    background-color: #7B9EDD;

} */

.addAssign .name3 {
    /* position: relative; */
    /* right: 24%; */
    margin-left: -10px;
    z-index: 3;
    animation-duration: 2s;
    color: #fff;

}

.addAssign .name3 svg {
    font-size: 30px;
    color: gray;
    background-color: #fff;
    border-radius: 50%;
}

.modalKam {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 4% 0;
}

.modalKam h1 {
    color: #353535;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modalKam h6 {
    color: #2F2E2E;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.kamModalButton {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 5%;
}

.kamModalButton button {
    background-color: #EC5252;
    color: #fff;
    text-transform: capitalize;
    width: 35%;
}

.kamModalButton button:hover {
    background-color: rgb(251, 54, 54);
    color: #fff;
    width: 35%;
}

.kamModalButton p {
    cursor: pointer;
    color: #181818;

}

.SelectBarStartPriority {
    width: 100%;
    font-size: 14px;
    color: #141313;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}



.SelectBarMenu {
    height: 40px;
    border-radius: 0;
    font-size: 13px !important;
    color: #141313;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;

}

.SelectBarMenu li {
    background-color: #16469d !important;
    font-size: 1px !important;
    border: 0;
    font-size: 50px;
}


.prioritySelector {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.prioritySelector>p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 80%;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}


.prioritySelector>ul {
    position: absolute;
    top: 110%;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    gap: 5px;
    left: 0;
    right: 0;

    background-color: white;
    z-index: 555;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
}

.prioritySelector>ul>li {
    padding: 5px 5px;
    border-radius: 8px;

}

.taskDisplayBtn button {
    width: 60%;
    background-color: #16469d;
    font-size: 12px;
    height: 25px;
}

.statusSelector {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;



}

.statusSelector>p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 10px;
    letter-spacing: 0.2px;
}


.statusSelector>ul {
    position: absolute;
    top: 110%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 0;
    right: 0;
    z-index: 555;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 6px;
    background-color: #fff;
}

.statusSelector>ul>li {
    padding: 10px 15px;
    border-bottom: 1px solid rgb(178, 176, 176);
    width: 100%;
    text-align: start;
}

.statusSelector>ul>li:nth-child(4) {
    border-bottom: none;
    width: 100%;
}

.statusSelector>ul>li:hover {
    background-color: #e8e7e7;

}

/* -------------------------------------- */
.taskModalDisplay {
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.taskModalDisplay span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.taskModalDisplay span p {
    display: flex;
    gap: 10px;
    align-items: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 16.94px;
    color: #262525;
}

.taskModalDisplay h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    color: #262525;
}

.emailSelection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.emailSelection label {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #474343;
}

.emailSelection>label>input {
    width: 50px;
}

/* ************************************************** */
.SortingDate {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;



}

.SortingDate>p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 10px;
    letter-spacing: 0.2px;
}


.SortingDate>ul {
    position: absolute;
    top: 110%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50px;
    right: -20px;
    z-index: 555;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 6px;
    background-color: #fff;
}

.SortingDate>ul>li {
    padding: 10px 15px;
    border-bottom: 1px solid rgb(178, 176, 176);
    width: 100%;
    text-align: start;
}

.SortingDate>ul>li:nth-child(4) {
    border-bottom: none;
    width: 100%;
}

.SortingDate>ul>li:hover {
    background-color: #e8e7e7;

}

.jjjjj svg {
    transform: rotateY(180deg)
}


.SortingDate svg {
    color: #323131;
}

.SortingDate svg:hover {
    color: #16469d;
}


.loadmoreBtn {
    margin-top: 5px;
    width: fit-content;
    border: 1px solid #bcbcbc;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
    /* background-color: #d2d2d2; */
    border-radius: 5px;
    text-transform: capitalize;
}

.loadmoreBtn:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

/* ////////////////////////////////////////////////////// */

.TimeDropdown{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8f92ad;
}
.TimeDropdown p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #141313; 
    padding: 0 10px;
    cursor: pointer;
}
.TimeDropdown ul{
   position: absolute;
   background-color: #16469d;
   top: 110%;
   left: 0;
   z-index: 555;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
}
.TimeDropdown ul li{
  list-style: none;
  padding: 10px 15px;
  width: 100%;
}
.TimeDropdown ul li:hover{
 background-color: #afcbff;
}



