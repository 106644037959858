.chatPannelBackDrop
{
    position: fixed;
    inset: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.048);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  
}
.chatPannelBackDropHide
{
    display: none;
}

.chatPannelMain
{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    background-color: #FAFAFA;
    width: 100%;
    max-width: 400px;
    transition: all 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
}

.chatPannelMainOpen
{
 transform: translateX(0);
}