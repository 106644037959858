@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    /* -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;  */
}

:root {
    --font-inter: 'Inter', sans-serif;

}