.MainSlider {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 5% 0;
    background: #F5F9FF;
    margin: 5% 0;
}

.startSlider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1300px;
}

.startSlider>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

.startSlider>div h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #16469d;
}

.startSlider>div h1 {
    color: #141313;
    font-size: 40px;
    font-weight: 700;
    
}

.startSlider>div>p {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

/* -----------------------Slider-------------------- */

.procurementSlider {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.UloginBoxStart2 {
    width: 100%;
    margin: 5% 0;
    gap: 10px;
    box-sizing: border-box;
    height: 60vh;
    max-height: 500px;
}

.slidesProcure {
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
    padding: 20px 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 50vh;
    max-height: 500px;
    border-radius: 6px;
    background-color: #fff;
    z-index: 99999;
    margin: 10% 0;
    cursor:grab;
    transition: 0.6s ease-in-out;
}
.slidesProcure:hover{
transform: scale(1.1);
box-shadow: rgba(67, 69, 71, 0.2) 0px 8px 24px;

}

.slidesProcure>div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    position: relative;

}

.slidesProcure>div h6 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: 1%;

}

.slidesProcure>div p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 20%;
    
}

.slidesProcureInfo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    position: absolute;
    bottom: 1%;
    border-top: 3px solid #f2f5f8;
    padding: 20px 0 0 0;
}

.slidesProcureInfo>div:nth-child(1) {
    width: 20%;
}

.slidesProcureInfo>div:nth-child(2) {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.slidesProcureInfo h2 {
    font-size: 18px;
    color: #141313;
    font-weight: 700;

}

.slidesProcureInfo h5 {
    color: #141313;
    font-size: 14px;
    font-weight: 400;

}
.SliderArrow{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 3%; */
    gap: 10px;
    
}
.SliderArrow svg{
  font-size: 50px;
  background-color: #F5F9FF;
  color: #141313;
  border-radius: 50%;
  

}
.SliderArrow svg:active{
  background-color: #fff;
  color: #16469d;

}

@media screen and (max-width:786px) {
    .startSlider>div{
       width: 85%;
    }
    .startSlider>div h4 {
        font-size: 18px;
    }
    .startSlider>div h1 {
        font-size: 25px; 
        text-align: center;
    }
    .startSlider>div p {
        font-size: 16px;   
    }
    .procurementSlider {
        width: 100% !important;
    }
    .slidesProcureInfo {
        gap: 35px;
    }
    .slidesProcure p{
        font-size: 14px;
    }
    .slidesProcureInfo>div:nth-child(2) {
        gap: 5px;
    
    }
    .slidesProcureInfo h2 {
        font-size: 15px;
    }
    .slidesProcureInfo h6 {
        font-size: 12px;
    }
    
}