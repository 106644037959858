/* Dropdown CSS */
.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    
    /* border: 1px solid black; */
    
}

.dropdownContainer input {

    color: #747775;
    font-weight: 500;
    margin-top: 2% !important;
}

.dropdown {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    border: 1px solid #747775;
    cursor: pointer;
    border-radius: 5px;
    color: #1F1F1F;
    overflow-Y: auto;
    font-weight: 550;
    font-size: 13px;
    text-indent: 5px;
    
}



.dropdown.open  {

    border: 1px solid #1F1F1F;
    outline: none;
}

.arrowIcon {
    transition: transform 0.3s ease;

}

.open .arrowIcon {
    transform: rotate(180deg);
}


.selectedCategories
{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}


.selectedCategory {
    display: inline-flex;
    align-items: center;
    margin-right: 1px;
    margin-top: 1px;
    padding: 4px 8px;
    background-color: #d6e8ff;
    color: #12141A;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 400;
}
.selectedCategories>p {
    
  
    color: #1F1F1F;
   
    font-size: 14px;
    font-weight: 400;
}
.closeIcon {
    font-size: 0.8rem !important;
    margin-left: 6px;
    cursor: pointer;
}

.dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    border-radius: 5px;
    z-index: 90008;
}

.dropdownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    width: 100%;
}

.dropdownItem >span
{
    font-size: 14px;
}

.dropdownItem > input
{
    all: revert;
}


.redirectRegisterToast {
    top: 20% !important;
}


