.cardrowData {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(51, 51, 51, 0.10);
    width: 100%;
    height: 55px;
}

.insideCardSingleElement {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* gap: 15px; */
    justify-content: space-between;
    align-items: center;
}

.insideCardSingleElementChild {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 30%;

}

.insideCardSingleElementChild button {
    padding-top: 0;

}

.levelInsideCard {
    width: 40%;
    display: flex;
    align-items: center;
}

.levelInsideCard span {
    font-size: 12px;
    border-radius: 5px;
    color:#484848;
    font-weight: 500;
    width: 40%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.levelInsideCard2 {
    width: 20%;
    display: flex;
}

.levelInsideCard2 .toolTipInsideCard {
    font-size: 14px;
    font-style: italic;
    color: #333;
    font-weight: 400;
    line-height: 16.9px;
    letter-spacing: 0.2px;
}

.insideCardSingleElement button {

    border: none;
    cursor: pointer;
    color: black;
    font-size: 14px;
    font-weight: 500;
    background-color: inherit;
    transform: translateX(2px);
}




/*VendorTasks */
.vendorStyleTasks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-left: 0.5px solid rgb(196, 196, 196);
    border-right: 0.5px solid rgb(196, 196, 196);
}

.vendorStyleTasks button {
    background-color: inherit;
    border: none;
}

.insideCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    cursor: pointer;
}
.insideCard li{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
}