.ContentMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 4% 0;
    font-family: 'Inter';
}

.ContentMain>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 5% 0;
    gap: 40px;
    max-width: 1300px;
}

.content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 25px;
}

.content h6 {
    color: #16469D;
    font-size: 20px;
    font-weight: 600;
}

.content h1 {
    color: #040815;
    font-size: 40px;
    font-weight: 700;
    width: 100%;
}

.content p {
    color: #596780;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    line-height: 30px;
    opacity: 70%;
}

.ContentDiv {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.ContentDiv .information {
    display: flex;
    flex-direction: column;
    width: 45%;

}

.ContentDiv .contentImg {
    display: flex;
    flex-direction: column;
    width: 50%;

}

.ContentDiv .contentImg img {
    width: 100%;

}

.information {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 30px;
}

.information>div {
    display: flex;
    width: 90%;
}

.information>div>div {
    width: 20%;
}

.information>div img {
    width: 40%;
}

.information>div>.iconContent {
    display: flex;
    width: 80%;
    gap: 0px;
    flex-direction: column;
}

.iconContent>p{
    color: #5d76a0;
    letter-spacing: -2%;
    line-height: 24px;
    font-weight: 400;
}
.iconContent>h4{
    font-size: 20px;
    line-height: 36px;
    letter-spacing: -3%;
    font-weight: 600;
}

@media screen and (max-width:786px) {
    .content {
        width: 100%;
    }
    .content h6, .content p {
        font-size: 16px;
    }

    .content h1 {
        font-size: 25px;
    }
    .ContentDiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }
    .information {
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }
    .information>div {
        width: 100%;
    }
    .iconContent h4 {
        font-size: 18px;
        width: 100%;
    }
    .iconContent p {
        font-size: 15px;
        width: 100%;
    }
    .contentImg {
        width: 100% !important;
        margin: 4% 0;
    }
 
}