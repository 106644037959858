.mainPage {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1692425810/Group_427319420_1_nllizv.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 10px;

}

.container {
    display: flex;
    flex-direction: column;

    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    /* height: 550px; */
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 20px 20px 50px 2px grey;
    gap: 10px;
    padding: 30px 20px;

}

.companyLogo {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    overflow: hidden;
    /* height: 55px; */
    max-width: 300px;
}

.companyLogo img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.welcome {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
}



/* button {
    margin-top: 5%;
    padding: 10px;
    background-color: #16469D;
    color: #FFFFFF;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    transition-duration: 0.4s;
    cursor: pointer;
} */

/* button:hover {
    background-color: #1752b8;
} */
a {
    text-decoration: none;
}


a {
    color: #16469D;
    font-weight: 500;
}

a:hover {
    color: #1752b8;
}

label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #252628;
}


.box2 {
    display: block;
    width: 100% !important;
    margin-top: 5%;
}

.footerbox {
    gap: 5%;
}

.box2 h1 {
    color: #7B8499;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: block;
}


.box2 h1 span {
    color: #12141A;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.VisibleShowPAss {
    position: absolute;
    right: 10px;
    bottom: 2px;
}