.uploadContainer
{
    width: 100%;
    min-height:100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e2e2;
    border: 1px solid #d2d2d2;
}