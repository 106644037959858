.formContainer
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
}

.modalHeader
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.modalHeader>h3
{
    font-size: 24px;
    font-weight: 400;
    color: #1F1F1F;
    display: flex;
    align-items: center;
    gap: 5px;
}
.modalHeader>h3>span
{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>h3>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}

.modalHeader>span
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}







.formGroup
{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    position: relative;
}
.formGroup>label
{
   font-size: 14px;
   font-weight: 500;
   color: #606778;
   line-height: 25px;
}

.formGroup>input
{
    width: 100%;
    height: 40px;
    border: 1px solid #747775;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}


.formGroup>input:focus
{
    border: 1px solid #1F1F1F;
}
.formGroup>textarea
{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 5lh;
    max-height: 8lh;
    border: 1px solid #747775;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}

.formGroup>textarea:focus
{
    border: 1px solid #1F1F1F;
}
