.header
{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;;
  z-index: 50;
  /* position: absolute; */
  background-color: white;
 
}
.headerImg
{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow: hidden;
    padding-left: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.headerImg img
{
    max-width: 100%;
    height: 100%;

}