.modalContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    box-shadow: 24;
    padding: 30px 30px;
    border-radius: 4px;
    width: 25vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;

}

.modalContainer::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}


.modalWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;

}

.modalHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.modalHeader>h2 {
    font-size: 24px;
    font-weight: 400;

}
.modalHeader>span {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>span>svg {
    width:100%;
    height: 100%;
    font-weight: 500;
}

.modalBody
{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.formGroup
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: relative;
}
.formGroup>label
{
   font-size: 14px;
   font-weight: 400;
   color: #1F1F1F;
   line-height: 25px;
}

.formGroup>input[type="text"]
{
    width: 100%;
    height: 40px;
    border: 1px solid #747775;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}

.formGroup>input[type="text"]:focus
{
    border: 1px solid #1F1F1F;
}

.uploadArea
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    height: 120px;
    border: 1px dashed #747775;
    border-radius: 4px;
    background-color: #F6F6F6;
}
.uploadArea>div
{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    font-size: 14px;
}

.browseBtn
{
    color: #16469D;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}


.fileRow
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: 12px 15px;
    background-color: #F6F6F6;
}
.cutIcon
{
    position: absolute;
    top: -8px;
    right: -8px;
}
.cutIcon>span
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #16469D;
}
.cutIcon>span>svg
{
    width: 80%;
    height: 80%;
    color: white;
}


.fileRowHeader
{
    display: flex;
    width: 80%;
    gap: 15px;
    align-items: center;
}

.fileRowHeader>span
{
    width: 20px;
    min-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    
    align-items: center;
}
.fileRowHeader>span>svg
{
    width: 100%;
    height:  100%;
    color: #16469D;
    
}

.fileRowHeader>h4
{
    width: calc(100% - 30px);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    text-wrap: wrap;
    word-wrap: break-word;
}
.fileRowHeader>h4>span
{
    font-size: 12px;
    font-weight: 400;
    color: #16469D;
    cursor: pointer;
    
}

.fileSize
{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10%;
}
.fileSize>h6
{
    font-size: 10px;
    font-weight: 400;
    color: #1F1F1F;
    margin-right: 10px;
}

.errorMsg
{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    font-size: 10px;
    font-weight: 400;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formGroupCheckBox
{
    display: flex;
    align-items: center;
    gap: 10px;
   
}

.formGroupCheckBox>label
{
   font-size: 14px;
   font-weight: 400;
   color: #1F1F1F;
   line-height: 30px;
}
.formGroupCheckBox>input
{
    width: 18px;
    height: 18px;
}

.formGroup>button
{
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #16469D;
    color: white;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
}