@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter&display=swap');

.uloginMain {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;


}

.uloginStart {
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: auto;

}
.otpSent h4{
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: gray;
}
.uloginBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    padding-left: 50px;
    
}
.boxTwo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    gap: 20px;
}
.resendOTP{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}
/* .resendOTP>button{
    font-weight: 400;
    font-size: 14px;

 
    gap: 10px;
    text-transform: capitalize;
    background-color: #ffff;
    text-decoration: none;
    border: none;
    cursor: pointer;
} */


.resendOTP>button>span{
    font-weight: 400;
    font-size: 10px;
    /* line-height: ; */
    /* color: gray; */
}


@media screen and (max-width:768px) {
.uloginBox
{
padding: 10px;
justify-content: center;
width: 100%;
}

.boxTwo
{
    display: none;
}
    
}


.inputColums
{
    display: flex;
    width: 100%;
    gap: 20px;
}

.slides {
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
}




.uloginBox h3 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
}

.uloginBox h6 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}


.uloginBox h5 {
    font-family: 'Inter', sans-serif;
    font-size: 60px;
    font-weight: 800;
}

.loginRegisterForm {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loginRegisterForm>div {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
}
.loginRegisterForm>div label{
   padding-bottom: 1%;
   padding-top: 1%;
}


.loginRegisterForm input {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #ccc;
    text-indent: 10px ;
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 !important;
}

.PasswordFeild {
    width: 100%;
    height: 36px;
    display: flex ;
    align-items: center;
    position: relative;
    
}

.Visible {
    position: absolute;
    right: 3%;
    top: 21px;
    cursor: pointer;
    color: #6C6C6C;
}
.uloginBox .registerButtonn{
    width: 30%;
    background-color: #16469d;
    color: #fff;
    text-transform: capitalize;
}
.inputColums div input:focus {
    border: 1.5px solid #000;
}

.inputColums div input::placeholder {
    color: #7B8499;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.loginRegisterForm label {
    color: #606778;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}


.phoneinputtt > div > div > div
{
    width: 45px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
}

.phoneinputtt button {
    color: #fff !important;
    background-color: #16469d;
    border-radius: 0 6px 6px 0 !important;
}

.phoneinputtt button:hover {
    background-color: #133F8D;
    color: #fff;
}

.box2 {
    display: block;
    width: 100% !important;
}

.box2 h1 {
    color: #7B8499;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: block;
}

.box2 button {
    background-color: #16469d;
}

.box2 button:hover {
    background-color: #285ab4;
}

.box2 h1 span {
    color: #12141A;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-decoration-line: underline;
    font-family: 'Inter', sans-serif;
}

/* ---------------------------------------------------- */


/* Dropdown CSS */
.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdownContainer,
input {
    color: #7B8499;
    font-weight: 500;
    outline: none;
    border: none;
}

.dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-indent: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px;
}

.dropdown.open,
.inputColums input:focus {
    border: 1px solid #7B8499;
    outline: none;
}

.arrowIcon {
    transition: transform 0.3s ease;
}

.open .arrowIcon {
    transform: rotate(180deg);
}

.dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    border-radius: 5px;
}

.dropdownItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3% 3% 2% 3%;
    cursor: pointer;
}

.dropdownItem span {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.selectedCategories {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.selectedCategory {
    display: inline-flex;
    align-items: center;
    margin-right: 1px;
    margin-top: 1px;
    padding: 4px 8px;
    background-color: #d6e8ff;
    color: #12141A;
    border-radius: 20px;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

.closeIcon {
    font-size: 0.8rem !important;
    margin-left: 6px;
    cursor: pointer;
}


