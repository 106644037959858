.Container
{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    gap: 20px;
    background-color: white;
   
    padding: 20px 30px;
}

.mainContainer
{
    width: 100%;

}

.inputGroup
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputRow
{
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.inputRow>label
{
    font-size: 14px;
    color: #585858;

}

.upBtn
{
    padding: 0 20px;
    height: 40px;
    background-color: #16459d;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
} 