.mainSession{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.sessionImg{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.sessionImg>h2{
    font-weight: 600;
    font-size: 30px;
    line-height: 23px;
    color: #000000;
}
.sessionImg>h3{
    display: flex;
    width: 30%;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.sessionImg>button{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    background-color: #16469D;
    padding: 10px 41px 10px 41px;
    border-radius: 4px;
    text-transform: capitalize;
}