

.mainClass{
 display: flex;
 flex-direction: column;
 gap: 20px;
}
.mainHeadings{
    display: flex;
    justify-content: space-between;
}
.mainHeadings>h1{
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    color: #222326;
}

.createButton>button{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}
.franchiseGrid>label{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1B1C1E;
}
.franchiseGrid>input{
    border: 1px solid #CAD0DB;
    padding: 10px 0px;
    border-radius: 8px;
    text-indent: 10px;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1B1C1E;
}

.createHeading{
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.accCreated{
    display: flex;
    justify-content: space-between;
}
.accCreated>h1{
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    color: #232426;
}
.createHeading>div>p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #232426;
}
.createGrid>label{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1B1C1E;
}
.createGrid>input{
    border: 1px solid #CAD0DB;
    padding: 10px 0px;
    border-radius: 8px;
    text-indent: 10px;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1B1C1E;
}
.shareAcc{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.shareLink{
    display: flex;
    justify-content: space-between;
}
.shareLink>h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #1D1F21;
}

.imgMainClass{
    display: flex;
    gap: 40px;
}
.imgMainClass>div>label{
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    letter-spacing: 0.75px; 
    color: #0F0F0F;

}
.franchiseGrid{
    position: relative;
}
.errorMessage{
    color: red;
    font-size: 10px;
    position: absolute;
    top: 100%;
}