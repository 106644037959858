.demoMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4% 0;
    margin: 4% 0;
}

.demoDiv {
    display: flex;
    justify-content: center;
    width: 85%;
    gap: 40px;
    max-width: 1300px;
}


.demoDiv .information {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.demoDiv .information h6 {
    color: #16469D;
    font-size: 20px;
    font-weight: 600;

}

.demoDiv .information h1 {
    color: #1E1B1B;
    font-size: 40px;
    font-weight: 700;
    width: 100%;
}

.demoDiv .information p {
    color: #40444A;
    font-size: 20px;
    font-weight: 400;
    width: 70%;

}

.demoDiv .information button {

    background-color: #16469D;
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    width: max-content;

}

.demoDiv .information button:hover {
    background-color: #2d6de2;
}

.demoDiv .contentImg {
    display: flex;
    width: 50%;
    object-fit: cover;

}

.demoDiv .contentImg img {
    width: 100%;


}

@media screen and (max-width:786px) {
    .demoDiv {
        display: flex;
        flex-direction: column;
    }

    .demoDiv .information {
        width: 100%;
        gap: 30px;
    }

    .demoDiv .contentImg {
        width: 100%;
    }

    .demoDiv .information h6 {
        font-size: 16px;
    }

    .demoDiv .information h1 {
        font-size: 25px;
    }

    .demoDiv .information p {
        font-size: 18px;
    }
    .demoDiv .information button {
        padding: 10px 15px;
    }
}