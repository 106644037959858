.attributeUnitMain
{
    position: absolute;
    top: 100%;
    width: 200px;
    border-radius: 6px;
    background-color: #FAFAFA;
    z-index: 500;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 8px;
}
.attributeUnitButton
{
    display: flex;
    width: 100%;

}
.attributeUnitButton > button
{
    border: none;
    background-color: #16469D;
    color: white;
    width: 40%;
    border-radius: 5px;
    height: 20px;
}

.attributeUnitEditor
{
    width: 100%;

}

.attributeUnitList
{
    display: flex;
    flex-direction: column;
    list-style: none;
    max-height: 150px;
    overflow-y: auto;
    
}
.attributeUnitList > li
{
padding: 10px 15px;
}
.attributeUnitList > li:hover
{
    background-color: #16459d31;
}