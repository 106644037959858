  .templetetableMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  .templetetable {
    width: 100%;
    border: 1px solid #d2d2d2;
    border-collapse: collapse;
  }

  .templetetableRow {
    border: 1px solid #d2d2d2;
  }

  .templetetableRow>th,
  .templetetableRow>td {
    text-align: left;
    padding: 10px;
  }

  .templetetableRow>th {
    height: 30px;
    background-color: #fafafa;

    font-size: 12px;
    font-weight: 600;


  }

  .templetetableRow>td {
    height: 40px;

    cursor: pointer;
    font-size: 14px;



  }

  .templetetableRow>th:nth-child(1),
  .templetetableRow>td:nth-child(1) {

    width: 80%;

  }
  .templetetableRow>th:nth-child(2),
  .templetetableRow>td:nth-child(2) {

    width: 20%;
    text-align: right;

  }