
.backDrop
{
position: fixed;
inset: 0;
z-index: 9999988;
}
.threeDotMain
{
    position: absolute;
    min-width: 150px;
    width: 15vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    list-style: none;
    top: 40px;
    z-index: 9999999;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.threeDotMain > li
{
    width: 100%;
    font-size: 14px;
    padding: 10px 15px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-weight: 400;
}
.threeDotMain > li:hover
{
background-color: rgba(74, 74, 248, 0.158);
}