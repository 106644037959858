
.specficationTabMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;

}

.richEditor {
    width: 100%;
    height: 100%;
    /* overflow-y: auto; */

}

.richEditor>div {
    width: 100%;
    height: 40vh;
}
