.selectorContainer
{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #D2D2D2;
    border-radius: 4px;
}
.selectorContainerOpen
{
    border: 1px solid #111111;

}


.selectorContainer>div
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backDrop
{
    position: fixed;
   inset: 0;

    z-index: 50;

}
.dropDownList
{
    position: absolute;
    top: 105%;
    left: 0;
    min-width: 100%;
    z-index: 55;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  
}
.dropDownList>li
{
    width: 100%;
    min-height: 30px;
    padding: 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background-color: #FFFFFF;
    /* border: 1px solid #D2D2D2; */
    border-top: none;
  

}
.dropDownList>li:last-child
{
    border-radius: 0 0 5px 5px;
}
.dropDownList>li:hover
{
    background-color: #F2F2F2;
}
