.profileMainContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px 25px;
    overflow: auto;
}

.profileFirstRow {
    display: flex;
    justify-content: space-between;

}

.profileFirstRow button {
    background-color: white;
    color: black;
    padding: 0;
    cursor: pointer;
    border: none;

}
.profileFirstRow button:hover {
    background-color: white;

}

.profileMainRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ProfileInfoTable {
    width: 60%;

    display: flex;
    flex-direction: row;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formInfoColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 15px;
}

.ProfileInfoTable input {
    width: 90%;
    padding: 10px;
    border: 1px solid #BDBDBD;
    border-radius: 5px;
    color: black;
    outline: none;
}
.ProfileInfoTable input:focus {
    border: 1px solid #606060;
}

.formGroup label {
    font-weight: 500;
    font-size: 12px;
    color: #404349;
    margin-bottom: 2px;
}


.profileLastRow {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.profileLastRow img {
    height: 80px;
    width: 200px;
}

.profileLastRow p {
    font-size: 14px;
    font-weight: 400;
    color: #353535;
}

.profileLastRow figcaption {
    font-size: 12px;
    color: #606060;
    font-weight: 400;
    padding: 5px;
}

.profileLastRow button {
    width: 130px;
    height: 40px;
    padding: 10px;
    background-color: #16469D;
    color: #FFFFFF;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    transition-duration: 0.4s;
    cursor: pointer;
}

.profileLastRow button:hover {
    background-color: #285ab4;
}


.ProfilePictureBox {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.ProfilePictureBox img {
    height: 130px;
    width: 130px;
    border-radius: 50%;
}

.ProfilePictureBox figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProfilePictureBox figure button {
    background-color: white;
    border: none;
    color: #3578DE;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.ProfilePictureBox figure button:hover {
    color: #164c9c;
}

.ProfilePictureBox >button {
    width: 130px;
    height: 40px;
    padding: 5px;
    background-color: white;
    color: #3578DE;
    border: 1px solid #3578DE;
    border-radius: 5px;
    box-shadow: none;
    transition-duration: 0.2s;
    cursor: pointer;
}

.ProfilePictureBox >button:hover {
    background-color: #3578DE;
    color: white;
    border: none;
}


@media (max-width: 1000px) {
    .profileMainRow {
        flex-direction: column-reverse;
        gap: 10px;
    }
    .ProfileInfoTable {
        width: 100%;
    }
    .ProfilePictureBox{
        width: 100%;

    }
}



.mobileInputField {
    position: relative;
}

.mobileInputField button {
    position: absolute;
    border-radius: 0 5px 5px 0;
    right: 12%;
    z-index: 2;
    border: none;
    top: 10px ;
    padding: 0;
    padding-right: 3px;
    cursor: pointer;
    color: #3578DE;
    font-weight: 600;
    background-color: inherit;
    transform: translateX(2px);
    background-color: #f8f8f8;
}
.mobileInputField button:hover {
    color: #0248b1;
    background-color: white;
}



.ChangeNumberModal {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.changeModalInfo {
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #282C3F;
}
.changeModalInfo>p{
    width: 70%;
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #282C3F;
}
.changeModalInput
{
    position: relative;

}
.changeModalInput input {
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgb(231 224 224);
    width: 100%;
    outline: none;
    border-radius: 5px;
}

.placeholder{
    display: block;
    color: #BDBDBD;
    position: absolute;
    top: 11px;
    left: 2%;
    font-weight: 400;
    font-size: 14px;
    padding-left: 5px;
    cursor: text;
  }
  
  .placeholder:after{
    content: '*';
    color: red
  }
  
  .emptyInput .placeholder:after {
    display: none;
  }

 .changeModalInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
    gap: 5px;
} 

 .VisibleS {
    color: #868686;
   position: absolute;
   right: 5%;
   top: 10px;

  } 
.changeModalInput input:focus {
    
    border: 1px solid #606060;
}

.changeModalInput p {
    margin-top: 5px;
    font-size: 12px;
    color: #94969F;
}
.changeModalInput p button{
    text-decoration: underline;
    color: #0248b1;
    background-color: white;
    border: none;
    cursor: pointer;
}
.changeModalInput p button:hover{
    color: #16469D;
}

.changeModalButton button {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    background-color: #2871df;
    color: #FFFFFF;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    transition-duration: 0.4s;
    cursor: pointer;
}
.changeModalButton button:hover {
    background-color: #16469D;
}

.verifyOTPInput fieldset {
    border: none !important;
    border-radius: 0;
    border-bottom: 2px solid #d0d0d0 !important;
    
  }
  
.verifyOTPInput input {
    color: #868686;
    font-size: 25px;
  }

.validationErrors {
    font-size: 12px;
    color: rgb(211, 0, 0);
    padding-left: 3px;
}

  /* CSS for Loader and tick mark: */
  
  
  
  .loaderContentBox {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .checkerContentBox {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

.animationCtn{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
      @-webkit-keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 200px
      }
  }
  
  @-ms-keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 200px
      }
  }
  
  @keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 0px
      }
  }
  
  @-webkit-keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 480px
     
      }
  
      100% {
          stroke-dashoffset: 960px;
        
      }
  }
  
  @-ms-keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 240px
      }
  
      100% {
          stroke-dashoffset: 480px
      }
  }
  
  @keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 480px 
      }
  
      100% {
          stroke-dashoffset: 960px
      }
  }
  
  @keyframes colored-circle { 
      0% {
          opacity:0
      }
  
      100% {
          opacity:100
      }
  }
  
  /* other styles */
  /* .Bsvg svg {
      display: none
  }
   */
  .inlinesvg .Bsvg svg {
      display: inline
  }
  
  /* .Bsvg img {
      display: none
  } */
  
  .iconOrderSuccess svg polyline {
      -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
      animation: checkmark 0.25s ease-in-out 0.7s backwards
  }
  
  .iconOrderSuccess svg circle {
      -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
      animation: checkmark-circle 0.6s ease-in-out backwards;
  }
  .iconOrderSuccess svg circle#colored {
      -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
      animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  } 



  .initialsContainer
  {
    width: 100px;
    height: 100px;
    background-color: orange;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .initialsContainer>p
  {
    font-size: clamp(2rem,3vw,3rem);
    color: white;
  }
  .resendOTP{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}
.resendOTP>button{
    font-weight: 400;
    font-size: 14px;
    /* line-height: ; */
    /* color: ; */
    gap: 10px;
    text-transform: capitalize;
    background-color: #ffff;
    text-decoration: none;
    border: none;
    cursor: pointer;
}
/* .resendOTP>button:hover{

    background-color: #ffff;
} */

.resendOTP>button>span{
    font-weight: 400;
    font-size: 10px;
    /* line-height: ; */
    color: gray;
}
