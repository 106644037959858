/* .svgHelper {
    position: relative;
    cursor: pointer;
}

.svgHelper svg+p {
    position: absolute;
    top: 100%;
    font-size: 8px;
    background-color: #585858;
    color: white;
    display: none;
    transition: all 0.3s ease-in-out;
    max-width: 50px;
    padding: 3px;
    word-wrap: break-word;
    text-align: center;

    
     left: 0;
    display: flex;
    justify-content: center; 
}

.svgHelper:hover svg+p {
    display: block;
} */

.d_flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.d_flex_center_column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

}


.d_flex_end {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.d_flex_start {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.workSpaceMain {
    background-color: white;
    height: 100%;
    padding: 20px 10px;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    overflow-x: auto;
}

.workSpaceMainDiv {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    height: 100%;
}

/* .workSpaceMainDiv>*
{
    width: 100%;
} */

.workSpaceHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    gap: 20px;
}

.workSpaceHeaderLeft {
    display: flex;
    flex-direction: column;
    /* align-items:; */
    max-width: 350px;

}

.workSpaceHeaderLeft>h1>span {

    /* height: 20px; */
    /* width: 20px; */

}

.workSpaceHeaderLeft>h1>span>svg {

    height: 100%;
    width: 100%;

}

.workSpaceHeaderLeft h1 {
    font-size: clamp(16px, 4vw, 20px);
    font-weight: 600;
    display: flex;
    /* justify-content: center; */
    align-items: center;

}

.workSpaceHeaderLeft h1>svg {
    font-size: 40px;
}

.workSpaceHeaderLeft h1>span {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workSpaceHeaderLeft h1>span>svg {
    width: 100%;
    height: 100%;
}

.workSpaceHeaderLeft>p {
    font-size: 14px;
    color: #585858;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

.workSpaceHeaderLeft p>span {
    width: 30px;
    height: 21px;
    background-color: #EEEEEE;
    padding: 2px 9px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    font-size: 12px;
}

.workSpaceHeaderSearchbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workSpaceHeaderSearchbar>input {
    width: 250px;
    background-color: #F3F3F3;
    border: none;
    outline: none;
    border-radius: 5px;
    text-indent: 10px;
    cursor: text;
    color: black;
    height: 35px;

}

.workSpaceHeaderRight {
    display: flex;
    gap: 20px;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    /* width: 350px; */
    /* flex-grow: 1; */
}

.WorkSpaceHeaderSorters {
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
    position: relative;
    /* width: 100% */
}

.WorkSpaceHeaderSorters p {
    font-size: 11px;
    text-decoration: underline;
    text-decoration-color: #585858;
    color: #585858;
}

.WorkSpaceHeaderSorters>h3 {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.WorkSpaceHeaderSorters>h3>svg {
    font-size: 16px;
}

.workSpaceHeaderSorterDropdown {
    position: absolute;
    top: calc(100% + 10px);
    width: 170px;
    left: 0;
    background-color: white;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 50;
}

.workSpaceHeaderDropDownBackDrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #0000009c; */
    z-index: 20;
}

.workSpaceHeaderSorterDropdown h5 {
    font-size: 16px;
    padding: 5px 15px;

}

.workSpaceHeaderSorterDropdown ul {
    display: flex;
    flex-direction: column;
    /* gap: 3px; */
    list-style: none;

}

.workSpaceHeaderSorterDropdown ul li {
    padding: 10px 15px;
    cursor: pointer;

}

.workSpaceHeaderSorterDropdown ul li:hover {
    background-color: #e3f5ff;

}


.headerbtn {
    min-width: 100px;
    padding: 0 15px;
    height: 40px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.workSpaceHeaderAddButton {
    width: 90px;

}

.workSpaceHeaderAddButton>button {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    height: 35px;
    border: none;
    border-radius: 5px;
    width: 100%;
}

.workSpaceHeaderAddButton>button:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 8px;
    background-color: white;
}

.workSpaceHeaderAddButton>button>svg {
    font-weight: 600;
    font-size: 25px;
}

.workSpaceHeaderAddButtonDropDown {
    position: absolute;
    left: 0;
    background-color: white;
}

.workSpaceHeaderAddButtonDropDown {
    position: absolute;
    background-color: white;
    width: 150px;
    list-style: none;
    display: flex;
    flex-direction: column;
    top: 120%;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 555;
}

.workSpaceHeaderAddButtonDropDown>li {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.workSpaceHeaderAddButtonDropDown>li:hover {
    background-color: #437de938;
}

.workSpaceHeaderProfiles {
    display: flex;
    justify-content: center;
    /* gap: -2px; */

}

.workSpaceHeaderProfiles>div {
    width: 24px;
    height: 24px;
    background-color: #F44336;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    color: white;

}

.workSpaceHeaderProfiles>span {
    width: 24px;
    height: 24px;
    border: 1px dashed black;
    border-radius: 50%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.WorkSpaceHeaderhelp {
    height: 24px;
    width: 24px;
}



.modalCont {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;

    box-shadow: 24;
    padding: 20px 30px;

    border-radius: 4px;
    width: 25vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalCont1 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;

    box-shadow: 24;
    padding: 20px 30px;

    border-radius: 4px;
    width: 30%;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalCont::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalCont::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalCont::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalCont::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.modalBtn {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 6px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;


}




@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modalHeader>div>svg {
    border: 2px solid black;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workSpaceThreeDotVertical>span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workSpaceThreeDotVertical {
    display: flex;
    justify-content: center;
}

.multiSaveButton {
    width: 95px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.multiSaveButton>button {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    background-color: #437EE9;
    height: 36px;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.multiSaveButton>button:hover {
    background-color: #437EE9;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


}

.multiSaveButton>button>span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;

}

.multiSaveButton>ul {
    position: absolute;
    background-color: white;
    width: 150px;
    list-style: none;
    display: flex;
    flex-direction: column;
    top: 120%;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 555;
}

.multiSaveButton>ul>li {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.multiSaveButton>ul>li:hover {
    background-color: #437de938;
}

.workSpaceHeaderRevisionMore {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.workSpaceHeaderRevisionMore>ul {
    position: absolute;
    top: 40px;
    width: 250px;
    background-color: white;
    list-style: none;
    z-index: 555;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 0;
}

.workSpaceHeaderRevisionMore>ul>li {
    padding: 10px 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.workSpaceHeaderRevisionMore>ul>li:hover {
    background-color: #437de938;
}


.workSpaceHeaderDecisionButton {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.workSpaceHeaderDecisionButton>button {

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    height: 35px;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;

    padding: 10px 15px;
    width: 100%;
    gap: 5px;
    cursor: pointer;
}

.workSpaceHeaderDecisionButton>button>span {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
}

.workSpaceHeaderDecisionButton>button>span>svg {
    font-size: 16px;
    font-weight: 500;
}

.workSpaceHeaderDecisionApprovalBtn {

    border-color: #437CE2;
    color: white;
    background-color: #437CE2;

}

.workSpaceHeaderDecisionApprovalBtn>span>svg {

    color: #FFFFFF;

}

.workSpaceHeaderDecisionRejectBtn {
    border-color: #EC5252;
    color: #EC5252;
    background-color: transparent;
}

.workSpaceHeaderDecisionRejectBtn>span>svg {

    color: #EC5252;

}

.popsBackDrop {
    position: fixed;
    inset: 0;
    z-index: 5000;
}



.tabHeaderMain {
    border-bottom: 1px solid grey;
    display: flex;
    position: relative;
}

.tabHeaderMain::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.404);
}

.revisionTab {
    padding: 10px 30px;
    cursor: pointer;
    display: flex;
    gap: 5px;


}

.revisionTab>span {
    width: 34px;
    height: 24px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F4F7;
    font-size: 12px;
    font-weight: 500;
    color: #344054;
}

.revisionTabActive {
    position: relative;
}

.revisionTabActive::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    left: 0;
    height: 4px;
    background-color: #16469D;
    z-index: 12;
    border-radius: 4px;
    /* border-bottom: 4px solid #16469D; */
}

.createGroupBtn {
    border: none;
    background-color: transparent;

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #16469D;
    cursor: pointer;

}

.CompareBtn {
    background-color: #16469D;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.backDrop {
    position: fixed;
    inset: 0;
    z-index: 120;
}


.tableSorterDiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.tableSorterDiv>p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tableSorterDiv>p>span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.tableSorterDiv>p>span>svg {
    width: 23px;
    height: 23px;
}

.tableSorterDropDown {
    position: absolute;
    top: 110%;
    list-style: none;
    min-width: 120px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 220;
}

.tableSorterDropDown>li {
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

}

.tableSorterDropDown>li:last-child {
    border-bottom: none;
}

.tableSorterDropDown>li:hover {
    background-color: #437de24f;
}


.orDivider {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 30px;
    position: relative;

}

.orDivider>span {

    z-index: 999;
    background-color: white;
    padding: 5px;
    font-size: 12px;
    color: #d2d2d2;

}

.orDivider::after {
    content: "";
    height: 1px;
    background-color: #d2d2d2;
    width: 100%;
    position: absolute;
    z-index: 992;
}




.rejectModalText {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 4% 0;
}

.rejectModalText>h2 {
    color: #353535;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.rejectModalText>p {
    color: #2F2E2E;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rejectModalButton {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 5%;
}

.rejectModalButton button {
    background-color: #EC5252;
    color: #fff;
    text-transform: capitalize;
    width: 35%;
    border: none;
    height: 35px;
    border-radius: 5px;
}

.rejectModalButton button:hover {
    background-color: rgb(251, 54, 54);
    color: #fff;
    width: 35%;

}

.rejectModalButton p {
    cursor: pointer;
    color: #181818;

}