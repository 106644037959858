.profileIconMain {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    
}

.profileIconMain>span {
    background-color: #7B9EDD;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

.ProfileIconMaximized {
    position: absolute;
    width: 310px;
    top: 100%;
    /* left: 50%; */
    display: flex;
    background-color: white;
    padding: 5px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
   transform: scale(0);
    /* transition: opacity 0.3s ease-in-out; */
    transition: transform 0.2s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    flex-direction: column;
    transform-origin: center;
    z-index: 9999999;
}

.ProfileIconMaximized>div {
    display: flex;
    padding: 10px 20px;
    width: 100%;
    flex-direction: column;
   

}

.viewProfile {

    border-top: 1px solid gray;
}


.profileIconMain:hover .ProfileIconMaximized {
    transform: scale(1);
    opacity: 1;
    
   
}

/* .profileIconMain:hover {
    z-index: 9999;
} */
.closeDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.closeDiv>span {
    color: black;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeDiv>span>svg {
    height: 100%;
    width: 100%;
}

.profileIconRow {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 15px;
    /* margin: 5px 0; */
}

.profileIconInitials {
    background-color: #7B9EDD;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
}

.profileIconInitials>span {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.pofileIconInfo {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
}

.pofileIconInfo>h3 {
    color: black;
    font-size: 18px;
    font-weight: 400;
}

.pofileIconInfo>p {
    color: black;
    font-size: 14px !important;
    font-weight: 400;
}


.viewProfile
{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    align-items: flex-start;
    
}
.viewProfile>a {
    color: black;
    font-weight: 400;
    font-size: 15px;
  
    transition: all 0.1s ease-in-out;
   
    
}

.viewProfile>a:hover {
    color: black;
    font-weight: 500;
}