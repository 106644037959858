.HeaderMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1698404943/Frame_427320614_nm16hy.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.HeaderMain .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    max-width: 1300px;
    gap: 10px;
    margin: 100px 0;

}

.HeaderMain .heading h1 {
    color: #262323;
    text-align: center;
    font-size: 72px;
    font-weight: 700;
   
}

.HeaderMain .heading h1 span {
    color: #16469d;
    text-align: center;
    font-size: 72px;
    font-weight: 700;
}

.HeaderMain .heading p {
    color: #3D3D4E;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    width: 80%;

}

.headButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
}

.headButton button {
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 30px;
    width: 20%;
    background-color: #16469d;
    color: #fff;
}

.headButton button:nth-child(1) {
    background: #16469d;
    color: #fff;
    
}

.headButton button:nth-child(1):hover {
    background: #2e3646;

}

.headerImg {
    width: 85%;
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* .headerImg>div {
    width: 85%;

} */

.headerImg img {
    width: 100%;
}
@media screen and (max-width:786px) {
    .heading {
        width: 85% !important;
        margin: 50px 0 !important;

    }
    .HeaderMain .heading h1 {
        font-size: 30px;
    }
    .HeaderMain .heading h1 span{
        font-size: 30px;
    }
      
    .HeaderMain .heading p {
        font-size: 18px;    
    }
    .headButton button {
       width: max-content;
    }
    
 
}
