* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainTask {
    width: 100%;
    background-color: #fff;

}

.divider
{
    width: 95%;
    /* max-width: 1200px; */
    display: flex;
    height: 90%;
    max-height: 90%;

}
.modalClass {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    background-color: #ffff;
    /* padding: 10px; */
    height: 90%;
}
.chattComp{
    width:30%;
  
}
.taskNav {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
  
}

.taskNav>h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    box-shadow: 7px;
  
    /* box-shadow: '100px 80px 100px 50px ',   */
}

.createTask {
    /* width: 100%; */
    font-weight: 600;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.4);
    padding: 10px;
}


.taskNav>h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #3F3F3F;

}

.taskHeading {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 30px 0px 0px;

}

.taskHeading>input:nth-child(1){
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    padding: 5px;
    border: 1px solid #D7D7D7;
    /* background-color: #f6f4f4; */
    border-radius: 4px;
    min-height: 20px;
    text-indent: 10px;

}

.taskHeading>textarea {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #757575;
    padding: 5px;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    /* font-style: italic; */
    height: 60px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 0px  0px;
    max-height: 100vh;
    overflow-y:  auto;
}
.container::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
}

.container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
}

.container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the track */
}


.row {
    display: flex;

}
.logo>h1{
    display: inline-block;
    min-width: 24px;
    height: 24px;
    background-color: #7B9EDD;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
}
.list {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    
}

.listforCalendar {
    height: 35px;
}
.list>li {
    padding: 5px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    gap: 10px;
}

.list>li>button {
    background-color: #EEEBEB;
    box-shadow: none;
    border-radius: 4px;
    text-transform: capitalize;

}

.list>li>button:hover {
    background-color: #EEEBEB;
    box-shadow: none;
    border-radius: 4px;

}

.uploadFilesModal {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dragAndDrop {
    background-color: #F6F6F6;
    border: 1px dashed #AFAFAF;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
}

/* .dragAndDrop{

} */

.saveDiscardButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.saveDiscardButtons>button:nth-child(1) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    padding: 10px 20px;
    background-color: #16469D;
    text-transform: capitalize;
    border-radius: 4px;
}





/* Third Modal Style  */
.thirdModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #ffff;
}
.thirdModal:hover {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #ffff;
}

.thirdModal>h5 {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;

    color: #262525;
}
.thirdModal>h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    color: #262525;
}
.fourthdModal>h5 {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;

    color: #262525;
}

.emailSelection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.emailSelection>label {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #474343;
}
.emailSelection>label>input{
    width: 50px;
}
/* ************************************************** */


.divider{
    display: flex;

  
    
}

.selectCustomStyle {
    padding: 0px;
    height: 20px;
    /* border: ; */
    /* background-color: red; */
}


.selectorDiv
{
    display: flex;
    width: 100%;
    max-width: 250px;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
.ulselector
{
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 110%;
    z-index: 555;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    max-width: 250px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
.ulselector >li
{
    padding: 10px 15px;
}
.ulselector >li:hover
{
    background-color: #16459d4b;
}


/* *********************** */
.errorMessage{
    font-size: 12px;
    color: red;
    margin-top: -10px;
}
.formattedDate {
    display: flex;
  align-items: center;
    color: #6A6A6A;
    font-size: 14px; /* Adjust font size as needed */
  }
  
  .dateInput {
    display: flex;
    align-items: center;
  }

  .list1 {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    width: 100%;
}
.list1>li:nth-child(1) {
    padding: 13px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    /* gap: 10px; */
}
.list1>li:nth-child(1)>span {
  font-size: 14px;
}

.list1>li:nth-child(2) {
    padding: 5px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    /* gap: 10px; */
}
.listItem1 {
    display: flex;
    align-items: center;
    /* margin-right: 20px; */
}

.listItemText1 {
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
    color: #6A6A6A;
}

.selectCustomStyle1 {
    background-color: #F5F5F5;
    border-radius: 4px;
    /* padding: 10px; */
    max-height: 40px;
    font-size: 16px;
    color: #333;
    border: 1px solid #ccc;
}

.menuItem1 {
    display: flex;
    flex-direction: column;
}

.category1 {
    color: red;
    font-size: 12px;
}

.productName1 {
    font-size: 16px;
    margin-top: 5px;
}

.customSelect .MuiMenu-paper {
    box-shadow: none;
}

/* ------------------------------------------------------------- */
/* TaskPannel.module.css */

.productSelectContainer {
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    position: relative;
  }
  
  .productSearch {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .productSearch input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 6px;
    border-radius: 4px;
    margin-left: 8px;
  }
  
  .productList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: lightgray transparent;
  }
  
  .productItem {
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .productItem:hover {
    background-color: #f2f2f2;
  }
  
  .selectedProduct {
    background-color: #007bff;
    color: white;
  }
  
  .productInfo {
    display: flex;
    flex-direction: column;
  }
  
  .productCategory {
    font-size: 14px;
    color: #6A6A6A;
  }
  
  .productName {
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 4px;
  }
  
/* ------------------------------------------------------------------ */

.hoveredDiv:hover {
    background-color: #F6F6F6;
   transition: 1s;
}
.hoveredDiv2:hover {
    background-color: #F6F6F6; 
    transition: 1s;
  }
  .FileSelect {
    overflow-y: auto;
    max-height: 200px;
    scrollbar-width: thin; 
    scrollbar-color: #cccccc #f0f0f0; 
}

.FileSelect::-webkit-scrollbar {
    width: 10px;
}

.FileSelect::-webkit-scrollbar-track {
    background: #f0f0f0; 
}

.FileSelect::-webkit-scrollbar-thumb {
    background: #cccccc; 
    border-radius: 5px; 
}
.error-input {
    border: 1px solid red; /* Add red border for error input */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainTask {
    width: 100%;
    background-color: #fff;

}


.chattComp{
    width:30%;
  
}
.taskNav {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
  
}

.taskNav>h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    box-shadow: 10px;
  
    /* box-shadow: '100px 80px 100px 50px ',   */
}

.createTask {
    /* width: 100%; */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.4);
    padding: 7px;
}

.taskNav>h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #3F3F3F;

}

.taskHeading {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 30px 0px 0px;

}

.taskHeading>input:nth-child(1){
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    padding: 5px;
    border: 1px solid #D7D7D7;
    /* background-color: #f6f4f4; */
    border-radius: 4px;
    min-height: 20px;
    text-indent: 10px;

}

.taskHeading>textarea {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #757575;
    padding: 5px;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    /* font-style: italic; */
    height: 60px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 0px  0px;
    max-height: 100vh;
    overflow-y:  auto;
    flex-grow: 1;
}
.container::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
}

.container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
}

.container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the track */
}


.row {
    display: flex;

}
.logo>h1{
    display: inline-block;
    min-width: 24px;
    height: 24px;
    background-color: #7B9EDD;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
}
.list {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    
}


.list>li {
    padding: 5px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    gap: 10px;
}

.list>li>button {
    background-color: #EEEBEB;
    box-shadow: none;
    border-radius: 4px;
    text-transform: capitalize;

}

.list>li>button:hover {
    background-color: #EEEBEB;
    box-shadow: none;
    border-radius: 4px;

}

.uploadFilesModal {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dragAndDrop {
    background-color: #F6F6F6;
    border: 1px dashed #AFAFAF;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
}

/* .dragAndDrop{

} */

.saveDiscardButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.saveDiscardButtons>button:nth-child(1) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    padding: 10px 20px;
    background-color: #16469D;
    text-transform: capitalize;
    border-radius: 4px;
}





/* Third Modal Style  */
.thirdModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
}

.thirdModal>h5 {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;

    color: #262525;
}
.thirdModal>h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    color: #262525;
}
.emailSelection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.emailSelection>label {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #474343;
}
.emailSelection>label>input{
    width: 50px;
}
/* ************************************************** */


.divider{
    display: flex;

  
    
}

.selectCustomStyle {
    padding: 0px;
    height: 20px;
    /* border: ; */
    /* background-color: red; */
}


.selectorDiv
{
    display: flex;
    width: 100%;
    max-width: 250px;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
.ulselector
{
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 110%;
    z-index: 555;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    max-width: 250px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
.ulselector >li
{
    padding: 10px 15px;
}
.ulselector >li:hover
{
    background-color: #16459d4b;
}


/* *********************** */
.errorMessage{
    font-size: 12px;
    color: red;
    margin-top: -10px;
}
.formattedDate {
    display: flex;
  align-items: center;
    color: #6A6A6A;
    font-size: 14px; /* Adjust font size as needed */
  }
  
  .dateInput {
    display: flex;
    align-items: center;
  }

  .list1 {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    width: 100%;
}
.list1>li:nth-child(1) {
    padding: 13px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    /* gap: 10px; */
}
.list1>li:nth-child(1)>span {
  font-size: 14px;
}

.list1>li:nth-child(2) {
    padding: 5px 10px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    width: 25%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #3F3F3F;
    display: flex;
    align-items: center;
    /* gap: 10px; */
}
.listItem1 {
    display: flex;
    align-items: center;
    /* margin-right: 20px; */
}

.listItemText1 {
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
    color: #6A6A6A;
}

.selectCustomStyle1 {
    background-color: #F5F5F5;
    border-radius: 4px;
    /* padding: 10px; */
    max-height: 40px;
    font-size: 16px;
    color: #333;
    border: 1px solid #ccc;
}

.menuItem1 {
    display: flex;
    flex-direction: column;
}

.category1 {
    color: red;
    font-size: 12px;
}

.productName1 {
    font-size: 16px;
    margin-top: 5px;
}

.customSelect .MuiMenu-paper {
    box-shadow: none;
}

/* ------------------------------------------------------------- */
/* TaskPannel.module.css */

.productSelectContainer {
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    position: relative;
  }
  
  .productSearch {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .productSearch input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 6px;
    border-radius: 4px;
    margin-left: 8px;
  }
  
  .productList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: lightgray transparent;
  }
  
  .productItem {
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .productItem:hover {
    background-color: #f2f2f2;
  }
  
  .selectedProduct {
    background-color: #007bff;
    color: white;
  }
  
  .productInfo {
    display: flex;
    flex-direction: column;
  }
  
  .productCategory {
    font-size: 14px;
    color: #6A6A6A;
  }
  
  .productName {
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 4px;
  }
  
/* ------------------------------------------------------------------ */

.hoveredDiv:hover {
    background-color: #F6F6F6;
   transition: 1s;
}
.hoveredDiv2:hover {
    background-color: #F6F6F6; 
    transition: 1s;
  }
  .FileSelect {
    overflow-y: auto;
    max-height: 200px;
    scrollbar-width: thin; 
    scrollbar-color: #cccccc #f0f0f0; 
}

.FileSelect::-webkit-scrollbar {
    width: 10px;
}

.FileSelect::-webkit-scrollbar-track {
    background: #f0f0f0; 
}

.FileSelect::-webkit-scrollbar-thumb {
    background: #cccccc; 
    border-radius: 5px; 
}
.error-input {
    border: 1px solid red; /* Add red border for error input */
}



.AttachmentColumnRr
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}
.AttachmentColumnRr > div
{
    display: flex;
    flex-wrap: wrap;
   
}


.AttachmentColumnRr > div > div
{
    /* flex: 1 0 80px; */
    min-width: 110px;
    flex-shrink: 0;
}



/* Datepicker css */

/*To hide icon*/
/* .dateInput::-webkit-calendar-picker-indicator {
    display: none;
  } */
  
  .selectlistModal{
    max-height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px 5px;
  }
  .selectlistModal >li {
   cursor: pointer;
    padding: 7px 5px;
  }
  .selectlistModal >li:hover {
    background-color: #e3e3fe;

  }