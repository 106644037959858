


.slick-initialized .slick-slide>div {
    margin-left: 1%;
    margin-right: 1%;
}

.slick-initialized .slick-slide>div {
    margin: 0 20px;
    
}

