.header
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.header h2
{
    font-size: 20px;
    font-weight: 600;
}

.orgDetailsCont
{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: #EBF1FD80;
    padding: 20px;

}
.orgDeailsWrapper
{
    display: flex;
    width: 100%;
    max-width: 800px;
    justify-content: flex-start;
    justify-content: space-between;
   flex-direction: column;
   gap: 20px;
}

.OrgRow
{
    display: flex;
    width: 100%;
   
}

.OrgCol
{
    display: flex;
    flex-direction: column;
    width: 33%;
    gap: 10px;
}

.OrgCol>h5
{
display: flex;
gap: 10px;
align-items: center;
line-height: 19px;
}
.OrgCol>h5>span
{
    width: 18px;
    height: 18px;
display: flex;
justify-content: center;
align-items: center;
}

.OrgCol>h5>span>svg
{
    width: inherit;
height: inherit;
}
.tabCont
{
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.tabSelectorWrapper
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
   
    border-bottom: 2px solid #00000020;
}

.tabItem
{
    font-size: 15px;
    cursor: pointer;
    padding: 8px 5px;
    color: #000000;
}

.active
{
    font-weight: 600;
    
  
    border-bottom: 3px solid #16469D;
}

.tabAddBtn
{
    width: 120px;
    min-width: max-content;
    border: 1px solid #d2d2d2;
    background-color: transparent;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    outline: none;
}

.tabAddBtn:hover
{
    border: 1px solid #7c7c7c;

}