.chatHeaderMain
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px;
    border-bottom: 2px solid #D2D2D2;
}

.chatHeaderMain h3
{
  
font-size: 14px;
font-weight: 600;
line-height: 16px;


}

.chatHeaderMain > div
{
display: flex;
gap: 20px;
align-items: center;

}
.chatHeaderMain > div >span
{
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #44475C;
}