.toastMain
{
    position: fixed;
    top: 8%;
    min-height:max-content ;
    min-height: 50px;
    min-width: 300px;
    max-width: 350px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    right: 0;
    gap: 10px;
    border-radius: 4px;
    z-index: 888899899898;

}

.toastMain>span
{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;

}
.toastMain>span>svg
{
    width: 100%;
    height: 100%;
    color: inherit;
}

.success
{
    background-color: #2e7d32;
   
    color: white;
}

.error
{
    background-color: #D93025;
    color: white;
}
.caution
{
    background-color: #EDBD15;
    color: white;
}