.MainCompoUnauth {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    gap: 25px;
    justify-content: center;
    align-items: center;
}
.MainCompoUnauthBox {
    display: flex;
    width: 600px;
    height: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px
}

.mainHeadingError {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:0;
}

.mainHeadingError img {
    height: 200px;
    /* width: 150px; */
}
.mainHeadingError h1 {
    font-size: 150px;
}

.uauthMeassage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px

}
.uauthMeassage {
    justify-content: center;
}

.uauthMeassage p {
    text-align: center;
    line-height: 1.5; /* Adjust line height as needed for spacing between lines */
}