.mainPage {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1692425810/Group_427319420_1_nllizv.png');
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    padding: 10px;
}


.container {
    display: flex;
    flex-direction: column;
   
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    /* height: 550px; */
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 20px 20px 50px 2px grey;
    gap: 20px;
    padding: 30px 20px;
}

.companyLogo {
    display: flex;
    justify-content: center;
   width: 100%;
   align-items: center;
    overflow: hidden;
    height: 55px;
    max-width: 300px;
}
.companyLogo img
{
    max-height: 100%;
}

.registerLink > p
{
    font-size: 15px;
    color: #000000;
}

.registerLink > p > a
{
    color: #16469D;
}
.VisiblePass{
    position: absolute;
    top:5px;
    right: 10px;
}




/* 
.container form {
    display: flex;
    width: 80%;
    flex-direction: column;
    gap: 7px;
}

.container input, .container button {
    width: 100%;
}

.forgotPasswordLink {
    align-self: flex-end;
    font-size: 12px;
    
}

.container button {
    padding: 10px;
    background-color: #16469D;
    color: #FFFFFF;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    transition-duration: 0.4s;
    cursor: pointer;
}

.container button:hover {
    background-color: #1752b8;
}
.container a {
    text-decoration: none;
    color: #16469D;
    font-weight: 500;
}
.registerLink {
    font-size: 13px;
}


.container a:hover {
    color: #1752b8;
}

 */
