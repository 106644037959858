.ProStart {
    display: flex;
    width: 100%;
}

.ProMain {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;
    gap: 30px;
}

.pageCont {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 30px;


}

.productWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* height: 60vh; */
    max-height: 50vh;
    
    overflow-y: auto;

}

.pageCont>h1 {
    background-color: #fff;
    padding: 10px;
    font-weight: 600;
    font-size: 25px;

}

.prodata {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.proImg
{
    width: auto;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.proImg>img
{
    height: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
}

.proInfo
{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
}
.headerWrapper
{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.headerWrapper>h1
{
    font-size: 16px;
    font-weight: 600;
    color: black
}

.headerIconWrapper
{
    width: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerIconWrapper svg
{
    width: 25px;
    height: 25px;

}

.proInfo>h5
{
    font-size: 14px;
    font-weight: 400;
    color: black;
}

.confirmBtn
{
    background-color: #16469D;
    padding: 10px 20px;
    max-width: max-content;
    color: #FFFFFF;
    border-radius: 7px;
    border: none;
    font-size: 14px;
}