.MainStatic {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #f6faff;
    padding: 40px 0;
}

.StartStatic {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 85%;
    max-width: 1300px;
    max-width: 1300px;
    gap: 50px;
}

.StartStatic h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    width: 60%;
    color: #0D121F;
}

/* ---------------------------------- */
.sliderStatic {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sliderStatic>div {
    width: 50%;
    gap: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sliderStatic>div>div:nth-child(2) {
    width: 100%;
    gap: 50px;
    display: flex;
    flex-direction: column;

}

.sliderStatic .sliderButton {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    width: 80%;
    height: 55px;
    border-radius: 30px;
}

.sliderStatic .sliderButton button {
    border-radius: 30px;
    background-color: #16469d;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    width: 50%;
    transition: 0.4s ease-in-out;
    height: 100%;
    box-shadow: none;
}



.sliderStatic>div h2 {
    color: #0D121F;
    font-size: 24px;
    font-weight: 700;

}

.sliderStatic>div ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 40px;

}

.sliderStatic>div ul li {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;

}

.sliderStatic>div .firstUlListStyle li svg {
    color: #9CD323;
    width: 50px;
    /* background-color: #fff; */
}

.sliderStatic img {
    width: 100%;
    object-fit: cover;
}

.sliderStatic .SliderImg {
    width: 40%;
    height: 65vh;
    max-height: 500px;
}

.secondUlListStyle li svg {
    color: #ff4423;
    background-color: #fff;
}


@keyframes rotateAndFadeIn {
    0% {
        transform: rotateY(0deg);
        opacity: 0;
    }

    100% {
        transform: rotateY(90deg);
        opacity: 1;
    }
}

@keyframes rotateAndFadeOut {
    0% {
        transform: rotateY(90deg);
        opacity: 1;
    }

    100% {
        transform: rotateY(0deg);
        opacity: 0;
    }
}

@keyframes fadeAndIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeAndOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ImageRotateIn {
    animation: rotateAndFadeIn 0.4s ease-in-out;
}

.ImageRotateOut {
    animation: rotateAndFadeOut 0.4s ease-in-out;
}

.fadeIn {
    animation: fadeAndIn 0.4s ease-in-out;
}

.fadeOut {
    animation: fadeAndOut 0.4s ease-in-out;
}

@media screen and (max-width:786px) {
    .StartStatic {
        display: flex;
        flex-direction: column;
    }

    .StartStatic>div h1 {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

    .sliderStatic {
        display: flex;
        flex-direction: column;
    }

    .sliderStatic>div {
        width: 100%;
    }

    .sliderStatic>div h2 {
        font-size: 20px;
    }

    .sliderStatic>div .sliderButton {
        width: 100%;
    }

    .sliderStatic>div .sliderButton button {
        font-size: 14px;
        padding: 10px;
    }

    .sliderStatic>div ul li {
        font-size: 16px;
    }

    .SliderImg {
        width: 100% !important;
        height: 40vh !important;
        margin: 10% 0 0 0;
        max-height: 500px;
    }
  
}


/* ------------------------------------------------------ */
.reactSwitchCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  /* sliderButton */
  .reactSwitchLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    height: 50px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }

  
  .reactSwitchLabel .reactSwitchButton {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 50%;
    height: 45px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px
  }
  
  .reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .reactSwitchLabel:active .reactSwitchButton {
    width: 200px;
  }
  .reactSwitchButton button{
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    background-color: #fff !important;
    color: #000 !important;
  }