/* .ProdStart {
    display: flex;
}

.ProductMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.ProductMain>div {
    display: flex;
    width: 50%;
    max-width: 400px;
    flex-direction: column;
}

.ProductMain h1 {
    color: #000;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
}

.ProductMain h5 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.5px;
}

.AllProductmodal label {
    padding-bottom: 4px;
}


/* ******************************************************* */
/* .firstModalopen {

    
    display: flex;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(183, 181, 181);
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 6px;
    height: 35px;
    background-color: #fff;
}


.firstModalopen ul {
    position: absolute;
    top: 110%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    min-width: 300px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


}

.firstModalopen p {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.firstModalopen p::placeholder {
    font-size: 14px;
    color: gray;
    font-weight: 500;
}

.firstModalopen li {
    padding: 10px 15px;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    gap: 4px;
    cursor: pointer;
}

.firstModalopen li:hover {
    background-color: rgb(213, 225, 248);
}

.ProductSubmitButton {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
}

.ProductSubmitButton button {
    width: 40%;
    height: 35px;
    background-color: #16469d;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    text-transform: capitalize;
}

.ProductSubmitButton h2 {
    color: #16469d;
    width: 70%;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

}

.ProductSubmitButton h2:hover {
    color: #1565c0;
}

.AllProductmodal ul input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 10px;
    cursor: pointer;
}



.ProductSearchBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ProductSearchBar input{
    width: 100%;
    height: 35px;
    text-indent: 10px;
    border-radius: 8px;
    border: 2px solid #abaaaa;
    font-size: 14px;
}

.ProductSearchBar:hover{
    background-color: #fff !important;
} */ 

.ConfirmationMessage p{
    background-color:#edffe0;
    font-size: 15px;
    font-weight: 700;
    color: #6aaf3b;
    border: 2px solid #6aaf3b;
    border-radius: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    text-indent: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.ConfirmationMessage svg{
    color: #6aaf3b;
    margin-left: 10px;
}

.productSelectMain
{
    display: flex;
    width: 100%;
    /* flex-wrap: wrap; */
    height: 100vh;

}
.productSelectLeft
{
flex-grow: 1;
min-width: 400px;
justify-content: flex-start;
align-items: center;
height: 100%;
display: flex;
width: 100%;
padding: 0 30px;


}
.productSelectLeft > div
{
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: 80vh;

}
.productSelectHeaders
{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

}

.productSelectHeaders >h1
{
    
    font-size: 25px;
    font-weight: 600;
    line-height: 33px;
    
}

.productSelectHeaders >p
{
 
font-size: 16px;
font-weight: 400;
line-height: 27px;


}

.productSelectors
{
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    gap: 10px;
    width: 100%;
}

.productSelectorColumn
{
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
}
.productSelectorColumn > label
{
    font-size: 12px;
    line-height: 17px;
    color: #252628;
}

.productSelector
{
    width: 100%;
    position: relative;
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    color: #7B8499;
    /* overflow-Y: auto; */

}
.productSelector > p
{
font-size: 14px;
color: #252628;
display: flex;
width: 100%;
align-items: center;
justify-content: space-between;
}

.productDropDownDiv
{
    position: absolute;
    display: flex;
    top: 108%;
    z-index: 900008;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    padding-bottom: 10px;
    /* right: 100%; */

}
.productSearchBar
{
  
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.productSearchBar > input
{
    width: 100%;
    height: 35px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-indent: 10px;
    border-radius: 4px;
}

.productDropDownList
{
    list-style: none;
    flex-direction: column;
    display: flex;
    width: 100%;
    overflow-y: auto;
  

   
}

.productDropDownList > li
{
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    color: #252628;
}

.productDropDownList > li:hover
{
    background-color: rgba(44, 44, 212, 0.205);
}

.productSelectbtn
{
    display: flex;
    gap: 10px;
    
}

.proSelectPrimaryBtn
{
    width: 13vw;
    height: 40px;
    border: none;
    background-color: #16469D;
    border-radius: 7px;
    font-size: 14px;
    color: white;
    padding: 10px;

}

.proSelectSecondaryBtn
{
    color: #16469D;
    border-radius: 7px;
    font-size: 14px;
   
    padding: 10px;
    border: none;
    background-color: transparent;
    font-weight: 600;
}

.succesSVg svg{
    color: green !important;
}

.backButtonCSS:hover{
    box-shadow: 5px 10px 18px #888888;
}

.dropdownBackDrop
{
    position: fixed;
    inset: 0;
    z-index: 90000;
    background-color: rgba(0, 0, 0, 0.001);
    
}
