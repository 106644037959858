.ChatInputMain
{
    display: flex;
    padding: 10px 10px;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
}
.ChatInputMain > form
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
     position: relative;
  
}
.ChatInputMain > form > input
{
      background-color: #E2E2E2;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    border: none;
    border: none;
    text-indent: 10px;
    color: black;
   
}
.ChatInputMain > form > input:focus
{
    outline: 1px solid black;
}
.ChatInputMain > form > button
{
position: absolute;
right: 2%;
top: 0;
bottom: 0;
background: none;
border: none;
display: flex;
justify-content: center;
align-items: center;
}