.sideSlider ul .slick-active {
    width: 40px !important;
   
}

.sideSlider ul .slick-active button {
    width: 40px !important;
}

.sideSlider .slick-dots li button:before {
    width: 15px !important;
    content: '';
    background-color: gray;
    height: 5px;
    border-radius: 6px;
}

.sideSlider ul .slick-active button::before {
    width: 40px !important;
    content: '';
    background-color: black !important;
    height: 5px;
}