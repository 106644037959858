.boxTwo {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    gap: 20px;
}

.slides {
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
}

.slides > img
{
    display: flex;
    width: 50% !important;
}

/* .UloginBoxStart2 ul .slick-active {
    width: 40px !important;
}

.UloginBoxStart2 ul .slick-active button {
    width: 40px !important;
}

.UloginBoxStart2 .slick-dots li button:before {
    width: 15px !important;
    content: '';
    background-color: gray;
    height: 5px;
    border-radius: 6px;
}

.UloginBoxStart2 ul .slick-active button::before {
    width: 40px !important;
    content: '';
    background-color: black !important;
    height: 5px;
}
 */

.boxInfo {
    width: 100 !important;
    text-align: center;
    margin-top: 5%;
}

.boxInfo h4 {
    width: 100% !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3%;
}

.boxInfo h6 {
    width: 100% !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}