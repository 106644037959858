.templateStyle>button {
    color: #16469D;
    font-size: 15px;
    font-weight: 450;
    background-color: white;
    outline: none;
    border: none;
    cursor: pointer;
}

.templateStyle>button:hover {
    color: #0d3279;
}


/* Create Tasks*/


.dropdownItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
    gap: 10px;
    cursor: pointer;
}

.modalHeadingCo {
    font-size: 20px;
    font-weight: 535;
}

.modalHeadingNames {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ShippingForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 95%;
}

.formShippingItem {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
    width: 100%;
}

.formShippingItem textarea {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    height: 74px;

}

.commercialModalss {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    justify-content: center;
}

.formShippingItem button {
    outline: none;
    padding: 11px;
    font-size: 12px;
    background: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
}

.selectedShipTerms {
    width: 100%;
    border: 1px grey solid;
    min-height: 30px;
    border-radius: 5px;
    padding: 5px;
}

.itemsInDiv {
    margin-left: 5px;
    background-color: #d1f1ff;
    padding: 3px;
    border-radius: 5px;
    font-size: 13px;
}

.dropdownContainer {
    position: relative;
    width: 100%;
}

.dropdownContent {
    display: flex;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    flex-direction: column;
    gap: 5px;
    max-height: 120px;
    overflow-y: auto;
}




.requestModalClass {
    justify-content: flex-start;
    background-color: #EDFFE0;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: 30%;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid green;
    color: green ;
    font-weight: 500;
}

.requestModalIcon>svg {
    margin-top: 4px;
    color: green !important;
}

.requestModalSpan >span {
    text-decoration: underline;
    cursor: pointer;
}


/* zero templates */
.zerotemplateBox {
   width: 100%;
  height: 60vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center; 
   /* background-color: #0d3279; */
}
.zerotemplateInnerBox {
   width: 40%;
  height: 50%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center; 
   /* background-color: #0d3279; */
}

.zerotemplateSvg > img {
   height: 125px;
   width: 150px;
}

.zerotemplateInnerBox2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.zerotemplateInfo {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.zerotemplatebutton > button{
    padding: 8px;
    border-radius: 5px;
    color: white;
    background: #16469D;
    border: none;
    cursor: pointer;
}
.zerotemplatebutton >button:hover{
    background: #0d3888;
    border-radius: 5px;
}

.selectorMain
{
    /* width: 100%; */
    height: 100%;
    display: flex;
    align-items: center;
}
.selectorMain>p
{
    width: 100%;
    min-width: 200px;
    max-width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listDropDown
{
    position: absolute;
    width: 100%;
    bottom: 110%;
    list-style-type: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
}

.listDropDown > li
{
padding: 8px 10px;
}

.listDropDown > li:hover
{
    background-color: rgba(74, 74, 248, 0.158);
    cursor: pointer;
}



.templatesWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
}

.templateCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* border: 1px solid #ccc; */
}

.templateCard>p {
    font-size: 16px;
    font-weight: 600;
}

.templateCard>input {
    width: 18px;
    height: 18px;
}


.templatesWrapper::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.templatesWrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.templatesWrapper::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.templatesWrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}
