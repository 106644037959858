.unitSelectorWrapper
{
    width: 100%;
    height: 100%;
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.unitSelectorWrapper>p
{
    width: 100%;
    height: 100%;
    
}

.unitSelectorList
{
    position: absolute;
    top: 100%;
    z-index: 1000;
    width: 100%;
    
    max-height: 150px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    list-style: none;
    overflow: auto;

}
.backDrop
{
    position: fixed;
    inset: 0;
    z-index: 50;
}

.unitSelectorList>li
{
    padding: 10px 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
}

.unitSelectorList>li:hover
{
    background-color: #f5f5f5;
}

.unitSelectorEditor
{
    position: absolute;
    top: 100%;
    z-index: 1000;
    width: 100%;
    
    max-height: 250px;
    background-color: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.unitSelectorEditor>button
{

    border: none;
    background-color: #16469D;
    color: white;
    width: 40%;
    border-radius: 5px;
    height: 20px;
}

