.dropDownBackDrop
{
    position: fixed;
    z-index: 9999989;
    inset: 0;
}

.dropDownSorter
{
    position: absolute;
    top: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 5px;
    z-index: 9999999;
    min-width: 150px;
}

.dropDownSorterSearch
{
    display: flex;
    padding: 5px 5px;
    height: 40px;
    width: 100%;

}
.dropDownSorterSearch > input
{
    height: 30px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-indent: 5px;
}
.dropDownSorterList
{
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 5px 0;
    height: 100%;
    overflow-y: auto;
}
.dropDownSorterList>li
{

    min-height: 30px;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.dropDownSorterList>li:hover
{

   background-color: #ebf1fd;
}