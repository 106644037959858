.inputForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 300px;
    position: relative;
}

.inputRow {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2px;
    position: relative;
}

.inputRowCheckBox {
    display: flex;
    width: 100%;
    gap: 5px;
    align-items: center;

}

.inputRow>label {
    font-size: 12px;
    font-weight: 500;
    color: #252628;
}

.inputRow>input,.inputRow>select,.inputRow>textarea {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #CAD0DB;
    text-indent: 10px;
    color: #12141A;
}

.inputRow>textarea
{
    text-indent: 0 !important;
    padding: 10px !important;
}

.daysSelector {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #CAD0DB;

    color: #12141A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
}

.daysSelectorbtns {
    display: flex;
    gap: 5px;
    height: 100%;
    align-items: center;
    padding: 5px 0;
}

.daysSelectorbtns>span {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
}
.daysSelectorbtns>span:nth-child(1)
{
    background-color: rgb(246, 250, 255);
    color: #16469D;
}
.daysSelectorbtns>span:nth-child(2)
{
    background-color: #16469D;
    color: white;
}

.inputRow>input:focus,
select:focus,
textarea:focus {
    border-color: #252628;
}


.inputRow>span {
    font-size: 9px;
    position: absolute;
    top: 100%;
    color: rgb(211, 9, 9);
    font-weight: 600;
}

.formBottomLine>p {
    font-size: 11px;
    line-height: 16px;
    color: #7B8499;


}

.formBottomLine>p>a {
    font-weight: 500;
    color: #12141A;
    text-decoration: underline;
    cursor: pointer;
}

.formPrimarySubmit {
    width: 100%;
    background-color: #16469D;
    height: 40px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    margin: 0;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.modalSmallPrimaryButton {
    width: 35%;
    background-color: #16469D;
    height: 40px;
    font-size: 20px;
    color: white;
    font-weight: 300;
    margin: 0;
    border-radius: 5px;
    box-shadow: none;
    border: none;

    padding: 0;
    cursor: pointer;
}

.forgotPasswordLink {
    width: 100%;
    justify-content: flex-end;
    display: flex;

}

.forgotPasswordLink>a {
    font-size: 12px;
}