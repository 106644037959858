.tableCon
{
    display: flex;
    width: 100%;
    /* height: 100%; */
    align-items: flex-start;
    

}

.specitemplatetable
{
    width: 100%;
    border: 1px solid #D2D2D2;
    border-collapse: collapse;
}

.specitemplatetableRow
{
    border: 1px solid #D2D2D2;
}
.specitemplatetableRow>th,
.specitemplatetableRow>td
{
    text-align: left;
    padding: 10px;
   
}

.specitemplatetableRow>th
{
    height: 30px;
    background-color: #FAFAFA;
    font-weight: 600;
    font-size: 12px;
}
.specitemplatetableRow>td
{
    height: 40px;
  
    font-weight: 400;
    font-size: 14px;
}

.specitemplatetableRow>th:nth-child(1),
.specitemplatetableRow>td:nth-child(1)
{
    width: 60%;
}
.specitemplatetableRow>th:nth-child(2),
.specitemplatetableRow>td:nth-child(2)
{
    width: 20%;
    text-align: center;
}
.specitemplatetableRow>th:nth-child(3),
.specitemplatetableRow>td:nth-child(3)
{
    width: 20%;
    text-align: right;
}

.specitemplatetableRowIcon
{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.specitemplatetableRowStatus
{
    
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    width: max-content;
    border-radius: 4px;
    
}

.noTemplatePlaceHolder
{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.noTemplatePlaceHolderChild
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.noTemplatePlaceHolderChild> span 
{
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;

}

.noTemplatePlaceHolderChild> span >svg
{
    width: 100%;
    height: 100%;
    color: #585858;
}

.noTemplatePlaceHolderChild>p
{
    font-size: 14px;
    color: #585858;

}

.noTemplatePlaceHolderbtn
{
    height: 30px;
    display: flex;
    justify-content:center;
    align-items: center;
    background-color: #16469D;
    color: white;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    min-width: max-content;
    width: 10vw;
}

.noTemplatePlaceHolderbtn>span
{

    height: inherit;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.noTemplatePlaceHolderbtn>span>svg
{
    width: 100%;
    height: 100%;
    color: white;
}



.templatesWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
}

.templateCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* border: 1px solid #ccc; */
}

.templateCard>p {
    font-size: 16px;
    font-weight: 600;
}

.templateCard>input {
    width: 18px;
    height: 18px;
}


.templatesWrapper::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.templatesWrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.templatesWrapper::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.templatesWrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}
