.procaHelpMain {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    background: #F6FAFF;
}

.procaHelpMain svg {
    color: #16469d;
}

.procaHelpMain .procaHelpStart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    max-width: 1300px;
    padding: 80px 20px;
    height: 500px;
}

.procaHelpMain .procaHelpContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    gap: 45px;

}

.procaHelpMain .ProcaHelpQuestion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
}

.procaHelpContent h1 {
    font-size: 64px;
    color: #1A191E;
    font-weight: 500;
}

.procaHelpContent a {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #16469d;
    font-size: 18px;
    font-weight: 500;
}

.procaHelpContent>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.procaHelpContent>div p {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
}
.procaHelpContent>div p span:nth-child(1) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.procaHelpContent>div p span:nth-child(2) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

/* ---------------------------------Dropdown---------------------------------- */

.ProcaHelpQuestion .faqContainer {
    width: 100%;
    margin: 0 auto;
    transition: 2s ease-in-out;
}

.faqItem {
    border-bottom: 1px solid #ccc;
    /* margin: 8px 0; */
    padding: 15px;
    cursor: pointer;
}

.faqQuestion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    color: #1A191E;
}

.faqAnswer {
    display: block;
    transition: 0.5s ease-in-out;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #1A191E;
}
.faqQuestion svg{
    transition: 0.4s ease-in-out;
}
.rotated {
    transform: rotate(45deg);
}


@media screen and (max-width:786px){
    .procaHelpStart{
        display: flex;
        /* max-height: 400px; */
        min-height: 1000px;
        flex-direction: column;
        gap: 50px;
        width: 100%;
    }
    .procaHelpContent{
        width: 100% !important;
        margin-bottom: 10%;
    }
    .ProcaHelpQuestion{
        width: 100% !important;

    }

    .procaHelpContent h1 {
        font-size: 30px;
    }
    
    .procaHelpContent a {
        font-size: 16px;

    }

    
    
}