.VendorMain {
    display: flex;
    width: 100%;
}
.productSorting{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    gap: 35px;
    position: relative;
}
.productSorting ul{
    position: absolute;
    border-radius: 6px;
    min-height: 100px;
    top: 100%;
    left: -10%;
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: #fff;
    list-style: none;
    z-index: 150;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.productSorting>td
{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.productSorting>td>span
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.productSorting>ul>li{
    padding: 10px 20px;
    display: flex;
    gap: 2px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

}
.productSorting>ul>li:hover
{
    background-color: rgb(213, 225, 248);
}
/* ********************************Search**************************** */
.fileSorting{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    gap: 35px;
    position: relative;
}
.fileSorting>ul{
    position: absolute;
    border-radius: 6px;
    min-height: 100px;
    top: 100%;
    /* left: -10%; */
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: #fff;
    list-style: none;
    z-index: 150;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.fileSorting>td
{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.fileSorting>td>span
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.fileSorting>ul>li{
    padding: 10px 20px;
    display: flex;
    gap: 2px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

}
.fileSorting>ul>li:hover
{
    background-color: rgb(213, 225, 248);
}
