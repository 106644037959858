/* ===================table style */

.tableWraper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 15px;
    height: 100%;
    align-items: flex-start;
}

.ApprovalTable {
    width: 100%;
    border-collapse: collapse;
    outline: 1px solid #d2d2d2;
    border: 1px solid #d2d2d2;
    border-radius: 5px;

}

.tableHead {


    background-color: #FAFAFA;
    border: 1px solid #D2D2D2;
    height: 36px;
    background-color: #FAFAFA;
    position: sticky;
    /* z-index: 100; */
    top: 0;
    outline: 1px solid #D2D2D2;
}

.tableHead>td {
    padding: 0 10px;
    min-width: 200px;
    font-size: 14px;

}

.tableRow {
    width: 100%;
    height: 45px;
    border: 1px solid #d2d2d2;
}

.tableRow>td {
    padding: 0 10px;
    min-width: 200px;
    font-size: 14px;
}

.tableColumnSticky {
    position: sticky;
    left: 0;
    background-color: #FAFAFA;
    outline: 1px solid #D2D2D2;
    /* filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.24)); */
    z-index: 50;
}


.iconRow
{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

/* ===================table style */

/* progress wraper */

.progressWrapper {
    width: 100%;
    height: 100%;
    min-height: 30px;
    background-color: #EDEDED;
    font-size: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
   
   

}

.progressWrapper>p {
    color: white;
    font-size: 12px;
    z-index: 20;
    color: black;

}

.progressWrapper>span {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: green;
    border-radius: 5px;

}

/* progress wraper */

/* HISTORY modal */
.historyRowWrapper
{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
   
    
}

.historyRow
{
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    position: relative;

}
.historyRowProgressLine
{
    content: "";
    width: 3px;
    background-color: #d2d2d2;
    position: absolute;
    left: 18px;
    height: 46px;
    bottom: 90%;
}

.historyRowIcon
{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
align-items: center;
padding: 5px
}
.historyRowIcon>span
{
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 50%;
   
    

}
.historyRowIcon>span>svg
{
width: inherit;
height: inherit;
color: white;
}

.historyRowCont
{
    display: flex;
    flex-direction: column;
    gap: 2px;

}
.historyRowCont > div
{
display: flex;
gap: 25px;
align-items: center;

}

.historyRowCont > div >h4
{
    font-size: 14px;
}
.historyRowCont > div >span
{
    font-size: 12px;
    padding: 5px 8px;
    background-color: #d2d2d29d;
    border-radius: 4px;
}

.historyRowCont > p
{
   font-size: 12px;
   color: #a1a0a0;

}
/* HISTORY modal */