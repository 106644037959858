.admintable
{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    font-size: 14px;
    /* font-family: 'Roboto', sans-serif; */
    border-radius: 3px;
}

.admintableRow
{
    border: 1px solid #ddd;
    height: 45px;
   
    text-align: left;
   
}
.admintableRowheader
{
    background-color: #FAFAFA;
    height: 35px;
    position: sticky;
    top: 0;
    outline: 1px solid #ddd;
}
.admintableRow>*
{
padding: 0 10px;
min-width: max-content
}

.admintableRow>th
{
    font-size: 12px;
    font-weight: 600;
    position: relative;
}
.admintableRow>th>p
{
    font-size: inherit;
    font-weight: inherit;
    display: flex;
    align-items: center;
    gap: 5px;

}
.admintableRow>th>p>span
{
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admintableRow>th>p>span>svg
{
    height: inherit;
    width: inherit;
}
.admintableRow>tr
{
    font-size: 14px;
    font-weight: 400;
    
}

.actionIcons
{
    width: 100%;
    display: flex;
    gap: 10px;

}
.actionIcons>span
{
    cursor: pointer;
    font-size: 20px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
}
.actionIcons>span>svg
{
    width: inherit;
    height: inherit;
}


.decisionButton
{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    gap: 20px;
    padding: 10px 0;
    
}
.decisionButton>button
{
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out;
}
.approveButton
{
    border: 1px solid #ddd;
    background-color: #16469D !important;
    color: #fff !important;

}
.decisionButton>button:hover
{
    color: #16469D;
    
}


/* product selector table */

.productTableWrapper
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-y: auto;
  

}
.productTable
{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    font-size: 14px;
    /* font-family: 'Roboto', sans-serif; */
    border-radius: 10px;
   
}
.productTableRow
{
    border: 1px solid #ddd;
    height: 60px;
    /* border-radius: 10px; */
    text-align: left;
   
}

.productTableHeader
{
    background-color: #FAFAFA;
    height: 45px;
    position: sticky;
    top: 0;
    outline: 1px solid #ddd;
}

.productTableRow>*
{
padding: 0 10px;
}

.productTableDesc
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}
.productTableDesc>h4
{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #000000;

}
.productTableDesc>span
{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #16469D;

}
/* product selector table */


.decisionBtnWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    
}

.rowDecisionbtn
{
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: max-content;
    padding: 0 10px;
    border-radius: 4px;
    font-size:  12px;
    cursor: pointer;
}

.rowDecisionSecondarybtn
{
    color: #555353;
}
.rowDecisionPrimarybtn
{
    background-color: #16469D;
    color: white;
}

.disableButton{
    display: flex;
    padding: 4px;
    border: none;
}