.selectorWrapper
{
    display: flex;
    flex-direction: row;
   
    align-items: center;
    width: max-content;
    position: relative;
   
}
.selectorWrapper>p
{
    font-size: 12px;
    padding: 6px 2px;
    cursor: pointer;
    min-width: max-content;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.selectorWrapper>p>span
{
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backdrop
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    z-index: 99;
    

}

.dropDownList
{
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 7px;
    list-style: none;
    padding: 5px 0px;
  
}

.dropDownList>li
{
display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
font-size: 13px;
padding: 5px 0;
cursor: pointer;
}

.dropDownList>li:hover
{
    background-color: #F6FAFF;
}