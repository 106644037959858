.TaskChatPannelWrapper {
    display: flex;
    /* flex-direction: column; */
    /* gap: 30px; */
    width: 30%;
    /* height: 100%; */
    background-color: #FAFAFA;
    padding: 15px 0px 15px 0px;
    justify-content: center;
    align-items: center;
}

.chattSections {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    width: 100%;
    height: 100%;
    /* padding: 0px 0px 0px 10px; */
}

.taskTabSelectorsWrapper
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 10px; */
    width: 100%;
    height: 45px;
    border-bottom: 2px solid #ccc;
    /* padding: 20px 10px; */
}


.tabButtons{
    display: flex;
    gap: 15px;
    height: 100%;
   
   
}
.tabButtons>button{
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
   
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    border-radius: 0px;
    height: 100%;
}

.tabButtons>.activeTab {
    border-bottom: 4px solid #16469D;
    border-radius: 0px;
}

.handlecreateTaskModal
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
   

}
.handlecreateTaskModal>svg , .handlecreateTaskModal>span
{
    cursor: pointer;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
}


.chatBodyWrapper
{
    display: flex;
flex-direction: column;
flex-grow: 1;
max-height: calc(100% - 45px);


}

.commentsTab {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
}

.commentContainer
{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    padding: 12px 10px;
    border-bottom: 1px solid #ccc;
    width: 100%;
}
.chatCard  {
    display: flex;
    gap: 10px;
    padding: 10px;
    width: 100%;
}

.chatCard >h1 {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    background-color: #7B9EDD;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
    /* width: 100%; */
}
.commentDetailsWrapper
{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(100% - 34px);
}

.headings {
    display: flex;
    gap: 10px;
    width: 100%;
}

.headings>h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
    color: #7B9EDD;
    

}

.headings>h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    letter-spacing: 0.2px;
    color: #A1A1A1;

}

.discripton>h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
    color: #2C2929;
    text-wrap: wrap;
    width: 100%;
    /* max-width: 100%; */
    word-wrap: break-word;
    text-wrap: balance;

}




.commentFormWrapper
{
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-height: 10px; */
    border: none;
    padding: 0 10px;
  
    width: 100%;
}

.commentForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* min-height: 10px; */
    border: none;
  
    background-color: #E2E2E2;
    width: 100%;
    border-radius: 3px;
}

.commentForm > button
{
    background-color: transparent;
    border: none;
    outline: none;
    width: 10%;
}
.commentForm>input {
    height: 40px;
    width: 100%;
    text-indent: 10px;
   
    border-radius: 4px;
    border: none;
    background-color: transparent;
    width: 90%;

}

.commentForm>input:focus {
    border: none;

}















/* .chattHistoryTab {} */

.helpCancelIcons {
    display: flex;
    gap: 20px;
    padding-right: 5px;
}

/* *****************chatt History**************** */
.chattHistoryTab {
    display: flex;
    /* gap: 10px; */
    flex-direction: column;

}



.chatHistCard {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    padding: 15px 10px;
    width: 100%;
   
    flex-direction: column;
}

.chatHistWrapper
{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding: 9px 0px;
    border-bottom: 1px solid #ccc;
    
   

}
.chatHistheader
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;

}

.heading
{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    align-items: center;

}
.heading>span
{
    background-color: #f3dfbe;
    padding: 1px 5px;
    font-size: 13px;
}
.heading>h2
{
    font-size: 14px;
    font-weight: 500;
}


.headerDate
{
    font-size: 15px;
    font-weight: 500;
}




.historycircle>div>h1 {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    background-color: #7B9EDD;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
}

.historyHeadings {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.historyHeadings>h2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
    color: black;
    

}

.historyHeadings>h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    letter-spacing: 0.2px;
    color: #A1A1A1;
    background-color: yellow;
    padding: 1px 5px;

}

.historyHeadings span {
    font-size: 8px;
    background-color: orange;
    padding: 1px;
}

.writeComment>.Textfield {
    background-color: red;
}

/* ChattPanel.module.css */
.commentDivider {
    border: 1px solid #e0e0e0;
    margin: 10px 0;

}

.writeCommentContainer {
    display: flex;
    justify-content: center;
    min-height: 10px;
    border: none;
    padding: 0 10px;
}

.writeCommentContainer > button
{
    background-color: transparent;
    border: none;
    outline: none;
}
.writeCommentContainer>input {
    height: 40px;
    width: 100%;
    text-indent: 10px;
    background-color: #E2E2E2;
    border-radius: 4px;
    border: none;

}

.writeCommentContainer>input:focus {
    border: none;

}


.historyLogs {
    display: flex;
    flex-direction: column;
    margin-left: 33px;
    gap: 5px;
}
.historyLogs> p {
    font-size: 14px;

}