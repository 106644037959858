.emailMain{
    display: flex;
    width: 100%;
}
.emailVerify{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
 
}
.sliderT{
    width: 50%;
}
.emailVerify>h1{
    font-weight:590 ;
    font-size: 30px;
    line-height: 35.8px;
    color: #302D2D;
  
}

.emailForm{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: center;
    align-items: center;
}
.emailForm>h2{
    font-weight:510 ;
    font-size: 14px;
    line-height: 16.71px;
    color: #302D2D;
  
}
.muiInput{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.muiInput>button{
    width: 100%;
    border-radius: 10px;
    padding: 8px;
    background-color: #16469D;
    text-transform: capitalize;
}
.muiInput>button:hover{
    background-color: #16469D;
}
.resendOtp{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.resendOtp>button{
    /* font-weight:500; */
    font-size: 14px;
    line-height: 16.71px;
    /* color: #16469D; */
    text-transform: capitalize;
    background-color: #fff;
    
}
.resendOtp>button:hover{
    background-color: #fff;
    
}
.muiInput>h3{
    font-weight:400 ;
    font-size: 14px;
    line-height: 16.71px;
    color: #929292;
}
/* Remove the border and outline from the fieldset */
.EmailVerifyOTPInput fieldset {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #d0d0d0;
    padding: 0px;
  }
  /* Remove the border from the fieldset when hovered */
.EmailVerifyOTPInput fieldset:hover {
    border: none !important;
  }
  
  /* Apply bottom border when input is focused */
.EmailVerifyOTPInput input:focus {
    border-color: #ffff;
  }
  
/* Remove the border and outline from the input */
.EmailVerifyOTPInput input {
    width: 100%;
    font-size: 30px;
    padding: 0px;
    border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid #d0d0d0;
    /* transition: border-color 0.3s ease; */
  }

.EmailVerifyOTPInput input:hover {
     border: none ;
   
  }
