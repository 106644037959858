.taskCategoryTableMain {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: 100%;
}

.taskCategoryTable {
    width: 100%;
    border-collapse: collapse;

}

.taskCategoryTableHeader {
    height: 36px;
    background-color: #FAFAFA;
}

.taskCategoryTableHeader th {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    padding: 0 10px;



}

.taskCategoryTableHeader th:nth-child(1) {
    width: 40%;
}

.taskCategoryTableHeader th:nth-child(2) {
    width: 20%;
}

.taskCategoryTableHeader th:nth-child(3) {
    width: 20%;
}

.taskCategoryTableHeader th:nth-child(4) {
    width: 20%;
}

.taskCategoryTableData {
  
    border: 1px solid #D2D2D2;
    height: 45px;
}

.taskCategoryTableData td {
    padding: 0 10px;
    font-size: 14px;
    color: #141313;
  

}

.actionTd {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    gap: 35px;
}

.actionTd span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.actionTdMore {
    position: absolute;
    top: 100%;
    width: 250px;
    right: 0;
    background-color: white;
    display: flex;

    flex-direction: column;
    list-style: none;
    z-index: 150;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.actionTdMore li {
    padding: 15px 20px;
    display: flex;
    gap: 2px;
    font-size: 16px;
}

.actionTdMore li:hover {
    background-color: rgb(213, 225, 248);
}
.StatusPillMAin{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}
.StatusPill {
    width: 45%;
    max-width: 600px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: none;
    font-size: 14px;
}

.headerSorter {
    display: flex;
    align-items: center;
    height: 100%;
    /* width: 100%; */

}

.headerSorter>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerSorter>span>svg {
    font-size: 20px;
}

.headerSorterList {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-width: 170px;
    list-style: none;
    padding: 5px 0;
    z-index: 5003;
}

.headerSorterList>li {
    padding: 10px 15px;
    cursor: pointer;
}
.headerSorterList>li:hover
{
    background-color: rgb(213, 225, 248);;
}