.modalContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 500px; */
    background-color: white;
    box-shadow: 24;
    padding: 30px 30px;
    border-radius: 4px;
    width: 100%;
    max-width: 80vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 600px;
    overflow-y: auto;


}

.modalContainer::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}

.mainCont
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;

}

.modalHeader
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: auto;
}
.modalHeader>h3
{
    font-size: 24px;
    font-weight: 400;
    color: #1F1F1F;
    display: flex;
    align-items: center;
    gap: 5px;
}
.modalHeader>h3>span
{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>h3>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}

.modalHeader>span
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}


.infoCont
{
    display: flex;
    padding: 10px;
    gap: 20px;

}

.infoCont>p
{
    font-size: 20px;
}
.infoCont>p>span
{
    font-weight: 600;
}

.vendorCardsWrapper
{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 15px;
    overflow: auto;
    padding: 5px;
    
}

.vendorCard
{
    display: flex;
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.vendorCardName
{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-self: center;
    width: 10%;
}

.vendorCardRanks
{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
    padding: 0 10px;

}

.vendorCardRanks>div
{
    
    padding: 10px;
    border-radius: 7px;
    color: black;
    text-wrap: nowrap;

}