.adminFirstContainer {
    display: flex;
    width: 100%;
    min-height: max-content;
    /* height: calc(30% - 60px); */
}

.adminFirstInsideContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.adminHeadSection > p {
    font-size: 20px;
    font-weight: 500;
}

.AdminInfoTable {
    display: flex;
    flex-direction: row;
    gap: 10%;
}

.formAdminInfoColumn {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formAdminGroup {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.formAdminGroup h5 {
    border: none;
    background-color: white;
    color: black;
    font-weight: 600;
    font-size: 13px;
}
.formAdminGroup label {
    color: #7B8499;
    font-size: 13px;

}


.adminSecondContainer {
    display: flex; 
    flex-direction: column;
    width: 100%;
    height: 60%;
    gap: 20px;
    
    
}

.adminSecondHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-weight: 600;
    font-size: 15px;
    height: 60px;
}

.headpart button{
    font-size: 15px;
    outline: none;
    border: 1px solid black;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    width: 100px;
    height: 40px;
}

.headpart button:hover{
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.headpart span{
    font-weight: 800;
    font-size: 18px;
}

.adminSecondInfo {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e5f8;
    width: 100%;
    height: 300px;
    background-color: #F6F7FB;
    border-radius: 5px;
    border-bottom: 1px solid #e0e5f8;
}

.adminSecondTableHeadBo {
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e0e5f8;
}
.adminSecondTableHead {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    min-height: 60px;
    width: 94.7%;
}

.adminSecondTableMainSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
}

.adminRowWidth {
    display: flex;
    width: 100%;
    min-height: 70px;
    border-bottom: 1px solid #e0e5f8;
    background-color: white;
}
.adminRowData {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    background-color: white;
    padding-left: 15px;
}

.mappedAdmindata {
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    align-items: flex-start;
}

.mappedAdmindata > label {
    font-size: 15px;
    font-weight: 700;
}

.mappedAdmindata .mappedAdmindataPath {
    font-size: 12px;
    font-weight: 700;
    color: rgb(55, 116, 230);
}

