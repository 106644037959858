.questionsTabMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.questionsTable {
  border: 1px solid #d2d2d2;
  border-collapse: collapse;
}

.questionsTableRow>th,
.questionsTableRow>td {
  border: 1px solid #d2d2d2;
  text-align: left;
  min-width: max-content;

}

.questionsTableRow>th {
  height: 36px;
  background-color: #FAFAFA;
  position: sticky;
  top: 0;
  outline: 1px solid #d2d2d2;
  color: #141313;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  padding: 5px;
  text-align: center;


}

.questionsTableRow>td {
  height: 40px;

  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #656363;
}
.questionsTableRow>td>p
{
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

/* .questionsTableRow > th:nth-child(1) ,
.questionsTableRow > td:nth-child(1)
{
width: 3%;
padding: 5px;
}
.questionsTableRow > th:nth-child(2) ,
.questionsTableRow > td:nth-child(2)
{
width: 30%;
}
.questionsTableRow > th:nth-child(3) ,
.questionsTableRow > td:nth-child(3)
{
width: 15%;
}
.questionsTableRow > th:nth-child(4) ,
.questionsTableRow > td:nth-child(4)
{
width: 24%;
}
.questionsTableRow > th:nth-child(5) ,
.questionsTableRow > td:nth-child(5)
{
width: 23%;
}
.questionsTableRow > th:nth-child(6) ,
.questionsTableRow > td:nth-child(6)
{
width: 5%;

}

.questionsTableRow > td:nth-child(6)>span
{
    display: flex;
    justify-content: center;
    align-items: center;
} */

.questiontableFooter {
  width: 100%;
  border: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px;
  gap: 4px;
  cursor: pointer;

}

.questiontableFooter>span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.questiontableFooter>span>svg {
  width: 100%;
  height: 100%;
}

.questionRowTextarea {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  background-color: transparent;
  padding: 5px 0;
  form-sizing: content;
  min-height: 1lh;
  max-height: 8lh;
}

.questionRowTextarea2 {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  background-color: transparent;
  padding: 5px 0;
  form-sizing: content;
  min-height: 1lh;
  max-height: 8lh;
  background-color: #F6F6F6;
  border-radius: 4px;
}

.questionRowInput {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  background-color: transparent;
  padding: 5px 0;
  text-indent: 5px;
  color: #000000;
}

.questionRowSelect {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

/* -------------------------------CommercialTempleteViewKam---------------------------------------- */
.TempStyleMain {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  flex-direction: column;
  padding: 2%;
  gap: 20px;
}

.TempStyleSecond {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1%;
  margin-bottom: 10px;
}

.TempStyleSecondw>h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 19.5px;
  letter-spacing: 0.1px;
  color: #111827;
  margin-right: 20px;
}

.TempStyleSecondw>h2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #585858;

}

.searchBox {
  display: flex;
  gap: 20px;
}

.searchBox>input {
  background-color: #F3F3F3;
  padding: 8px 62px 8px 0px;
  text-indent: 10px;
  border-radius: 10px 0px 0px 10px;

}

.templetetable {
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.templetetable>table {
  width: 100%;
  border-collapse: collapse;

}

.linkTo>th {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #000000;
  background-color: #FAFAFA;
  border-radius: 8px 8px 0px 0px;

}

.templetetable>table>tr {
  border: 1px solid #D2D2D2;
}

.templetetable>table>thead>tr>th {
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.2px;
  color: #141313;
  padding: 8px 16px;
  border-width: 0px 0.5px 0.5px 0.5px;
  border: 1px solid #D2D2D2;
  text-align: left;
  display: flex;
  align-items: center;
  /* To vertically center the content in table cells */
  justify-content: space-between;
}

.linkTo>td {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.2px;
  color: #141313;
  padding: 8px 16px;
  border-width: 0px 0.5px 0.5px 0.5px;
  border: 1px solid #D2D2D2;
  text-align: left;
  display: flex;
  align-items: center;
  /* To vertically center the content in table cells */
  justify-content: space-between;
}

/* --------------------CommercialTempleteCreate------------------ */
.templetetable>table>tbody>tr {
  border: 1px solid #D2D2D2;
}

.templetetable>table>tbody>tr>td {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.2px;
  color: #141313;
  padding: 8px 16px;
  border-width: 0px 0.5px 0.5px 0.5px;
  /* border: 1px solid #D2D2D2; */
  text-align: left;
  display: flex;
  align-items: center;
  /* To vertically center the content in table cells */
  justify-content: space-between;
}

/* --------------------CommercialTempleteCreate------------------ */

/* Add styles for table headers and icons as needed */

/* Make table rows equal height with flex-grow */
.templetetable>table>tr {
  flex-grow: 1;
}

/* Shift the table to the left */
.templetetable>table {
  margin-right: auto;
}

/* .dataNotPresent{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
/* CommercialTempleteView.module.css */

.dataNotPresent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
  height: 50vh;
  /* Ensures the container takes up the full viewport height */
  gap: 10px;
}

.dataNotPresent>h1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9D9D9D;
}

.dataNotPresent>button {
  text-transform: capitalize;
  padding: 7px 7px 6.5px 7px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  background-color: #16469D;
}

.dataNotPresent>button:hover {

  background-color: #16469D;
}


.status {
  font-weight: bold;
  text-align: center;
}

.approve {
  color: green;
}

.pending {
  color: orange;

}

.reject {
  color: red;
}



/* --------------descritption-----------1 */


.specficationTabMain {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.descriptionBox {
  display: flex;
  width: 100%;
  /* justify-content: center;
    align-items: flex-start; */

}

.descriptionCompareTextBox {
  width: 100%;
  height: 20vh;
}