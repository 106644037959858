.tabHeaderMain {
    border-bottom: 1px solid grey;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.tabHeaderMain::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.404);
}
.tabHeaderWapper
{
    display: flex;
    align-self: start;
}
.revisionTab {
    padding: 10px 30px;
    cursor: pointer;
}

.revisionTabActive {
    position: relative;
    font-weight: 500;
}

.revisionTabActive::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    left: 0;
    height: 4px;
    background-color: #16469D;
    z-index: 12;
    border-radius: 4px;
    /* border-bottom: 4px solid #16469D; */
}

.searchContainer
{
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    padding: 5px 0;
    position: relative;

    
}
.searchBox
{
    width: 300px;
    min-width: 300px;
    height: 100%;
    width: 100%;
}

.searchBox>input
{
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #D2D2D2;
    text-indent: 10px;

}








.tabBodyMain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: auto;
}

.specficationTabMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;

}

.richEditor {
    width: 100%;
    height: 100%;
    /* overflow-y: auto; */

}

.richEditor>div {
    width: 100%;
    height: 40vh;
}

.SpecificationTabBottomButton {
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
}

.SpecificationTabBottomButton>button {
    width: 100px;
    height: 36px;
    background-color: #16469D;
    color: white;
    border: none;
    font-size: 14px;
}

.SpecificationTabBottomButton>p {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.attributeTableMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;


}



.attributeTable {
    border-collapse: collapse;
    overflow-x: auto;
    width: 100%;
    border: 1px solid #d2d2d2;
}

.attributeTable>thead>tr {
    height: 35px;
    background-color: #FAFAFA;

}

.attributeTable>tbody>tr {
    height: 45px;

}

.attributeTable>tbody td {
    min-width: 30px;
}

.attributeTable>tbody td:nth-child(2) {
    /* min-width: 250px; */
    width: 20%;
}

/* .attributeTable>thead th
{
    min-width: 170px;
} */
.attributeTable>tbody td,
.attributeTable>thead th {
    border: 1px solid #D2D2D2;
    /* display: flex; */
    padding: 5px 10px;
}

.attributeTable>thead th {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
}

.attributeTable>tbody td {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

.nameCell,
.unitCell {
    padding: 0 !important;

}

.nameCell>input,
.unitCell>select {

    height: 45px;
    width: 100%;
    border: none;
    border-radius: 0px;
    text-indent: 10px;
    color: #1b1b1b;
}

.nameCell>input:focus,
.unitCell>select:focus {

    outline: 1px solid black;

}

.valueField>input {
    height: 30px;
    width: 100%;
    border: 1px solid #B6B6B6;
    border-radius: 4px;
    text-indent: 10px;
}

.valueField>input:focus {

    border: 1px solid #1b1b1b;
    outline: none;
}

.remarkField {
    padding: 0 !important;
    min-width: 200px !important;

}

.remarkField>textarea {
    height: 45px;
    padding: 5px 0px;
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 0;
}

.remarkField>textarea:focus {
    outline: 1px solid #1b1b1b;

}

.tableFooterAddAttribute {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    gap: 5px;
    border-right: 1px solid #D2D2D2;
    border-left: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    width: 100%;
}

.tableFooterAddAttribute>span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.documentsTabMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0px;
}

.documentRow {
    display: flex;
    width: 100%;
    height: 48px;
    border: 1px solid #D2D2D2;

}

.documentRow>p {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    font-size: 14px;
    font-weight: 500;

}

.documentRow>div {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.documentRow>div>p {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0px 15px;

}

.documentRow>div>div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.documentRow>div>div>a {
    width: 25px;
    height: 25px;
    color: #1b1b1b;

}

.documentRow>div>div>a:hover {
    color: #1b1b1b;
}

.accordianDocumentabHeader {
    width: 100%;
    align-items: center;
    height: 40px;
    display: flex;
    border: 1px solid #D2D2D2;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}


.accordianDocumentabHeader>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.DocumentTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #D2D2D2;
}

.DocumentTableHead {
    height: 48px;
    background-color: #F6F6F6;
    border: 1px solid #D2D2D2;
}

.DocumentTableRow {
    height: 48px;
    border: 1px solid #D2D2D2;
}

.DocumentTableRow td:nth-child(1) {
    width: 50%;
}

.DocumentTableRow td:nth-child(2) {
    width: 16%;
    text-align: center;

}

.DocumentTableRow td:nth-child(3) {
    width: 16%;
    text-align: center;

}

.DocumentTableRow td:nth-child(4) {
    width: auto;
    padding-right: 10px;
}

.DocumentTableRow th,
.DocumentTableRow td {
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;


}

.DocumentTableRow th {
    font-weight: 500;
}

.documentIconRow {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: flex-end;
}

.documentIconRow>a {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.documentMoreDots {
    position: relative;
}

.documentMoreDots>ul {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 220px;
    list-style: none;
    z-index: 500;

}

.documentMoreDots>ul>li {
    padding: 10px 15px;
    font-size: 15px;
    color: #1b1b1b;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.unitBox {
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding: 8px;
    height: 100%;
}

.unitBox>p {
    padding: 5px;
    width: 100%;
    min-height: 25px;
    border-radius: 5px;
    border: 1px solid #B6B6B6;
}


.DefaultRevisionDocument {
    position: absolute;
    top: 100%;
    width: 280px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 15px;
    z-index: 999;
    border-radius: 10px;
    max-height: 250px;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}



.attributeHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


/* jump to Row Style */


.jumpListBackDrop
{
    position: fixed;
    inset: 0;
    z-index: 99998;
}
.jumpToRow {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;


}

.jumpListDropDown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99999;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow-y: hidden;
    padding: 10px 0;
    max-height: 35vh;
    min-width: 25vw;
    border-radius: 4px;
}

.jumpToRowList {
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100%;
    overflow-y: auto;
   

}

.rowSearchBar {
    /* padding: 8px 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}
.rowSearchBar>input {
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
    height: 40px;
    text-indent: 10px;
}
.jumpToRowList>li {
    padding: 8px 15px;
    text-wrap: wrap;

}

.jumpToRowList>li:hover {
    background-color: #16459d33;
}