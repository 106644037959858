.ImagesStartDiv {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.ImagesMain {
    display: flex;
    flex-wrap: wrap;
    max-width: 1300px;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin: 4% 0 0 0;

}

.ImagesMain .imgDiv {
    flex-grow: 1;
    flex-basis: 500px;
    background-color: #F6FAFF;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px 25px;
    border-radius: 15px;
    height: 500px;
    align-items: center ;
    justify-content: space-between;
    position: relative;
    
}

.ImagesMain .imgDiv:nth-child(3)  {
    flex-grow: 1;
    flex-basis: 380px;
    height: 550px;
    padding: 20px;
}

.ImagesMain .imgDiv:nth-child(4)  {
    flex-grow: 1;
    flex-basis: 380px;
    height: 550px;
    padding: 20px;
}
.ImagesMain .imgDiv:nth-child(5)  {
    flex-grow: 1;
    flex-basis: 380px;
    height: 550px;
    padding: 20px;
}
.ImagesMain .imgDiv:nth-child(5) img{
    display: flex;
    object-fit: contain ;
    height: 100%;
}


.ImagesMain .imgDiv h1 {
    font-size: 40px;
    font-weight: 500;
    max-width: 450px;
    

}

.ImagesMain .imgDiv p {
    font-size: 18px;
    font-weight: 500;
    color: #88898f;
    max-width: 450px;

}
/* .ImagesMain .imgDiv img {
    width: 90%;
    object-fit: contain !important;
    

} */

@media screen and (max-width:786px) {
    .ImagesMain .imgDiv h1 {
        font-size: 25px;
    }

    .ImagesMain .imgDiv p {
        font-size: 16px;
    }
    .ImagesMain .imgDiv{
        max-height: 380px;
    }
    .ImagesStartDiv{
        margin-bottom: 10%;
    }
    .ImagesMain .imgDiv:nth-child(5) img{
        display: flex;
        object-fit: contain ;
        height: 100% ;
    }
}

.featureCardBottom
{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.featureCardBottom>img
{
   width: 90%;
    object-fit: contain;
    display: block;
}