.userTablerow2 {
    grid-template-columns: 1.2fr repeat(4, 1fr);

}

svg {
    cursor: pointer;
}

.VendorBtn {
    width: 25%;
    text-transform: capitalize !important;
    font-size: 14px;
    background-color: #16469d;
}

.noDataComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.noDataComponent>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.noDataComponent>div h6 {
    color: #9D9D9D;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16p
}

.noDataComponent button {
    width: 25%;
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 6px;
    border: none;
    padding: 6px 0;
    align-items: center;
    text-transform: capitalize;
    font-size: 14px;
    background-color: #16469d;
    cursor: pointer;
}

.noDataComponent button:hover {
    background-color: #1f4fa8;
}




