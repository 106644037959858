.approvalVendorMain
{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 15px;
    height: 100%;
    align-items: flex-start;
}

.approvalVendorTable
{
    width: 100%;
    border-collapse: collapse;
   
    /* table-layout: fixed; */
    border-radius: 6px;
    border: 1px solid #D2D2D2;
    padding-bottom: 10px;
   
}
.tableHead
{
    border: 1px solid #D2D2D2;
    height: 36px;
    background-color: #FAFAFA;
    position: sticky;
    z-index: 100;
    top: 0;
    outline:1px solid #D2D2D2;
}
.tableRow
{
    height: 48px;
    border: 1px solid #D2D2D2;

}
.tableHead > td
{
    font-size: 12px;
    font-weight: 600;
    padding:0 15px ;
}
.tableRow > td
{
    min-width: 180px;
    width: min-content;
    padding:0 15px ;
    font-size: 14px;
}

.tableRow  *
{
    font-size: 14px;
}

.tableColumnSticky
{
    position: sticky;
    left: 0;
    background-color: #FAFAFA; 
    outline:1px solid #D2D2D2;
   filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.24));
    z-index: 1;
}
.tableColumnSticky2
{
    position: sticky;
    left: 0;
    background-color: #FAFAFA; 
    outline:1px solid #D2D2D2;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.24));
    z-index: 1;
}

.tdRow
{
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

}

.tdRow > input
{
    width: 15px;
    height: 15px;
}

.actionsRow
{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

.actionsRow > span
{
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
}

.actionsRow > span > svg
{
    width: 25px;
    height: 25px;
}