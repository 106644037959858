.modalClass {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: 100%;
    background-color: #FAFAFA;
    padding: 13px 0px 10px 0px;
}

.chattSections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 0px 0px 0px 10px;
}

/* 
.commentsSection{
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #;
    border-radius: 50%;
    width: 10%;
} */

.circle {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.circle>h1 {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    background-color: #7B9EDD;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
}

.headings {
    display: flex;
    gap: 10px;
}

.headings>h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
    color: #7B9EDD;

}

.headings>h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    letter-spacing: 0.2px;
    color: #A1A1A1;

}

.discripton>h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
    color: #2C2929;

}

.buttonSection {
    display: flex;
    /* justify-content: space-around; */
    gap: 10px;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;

}

/* ... (other styles) */
.tabButtons{
    display: flex;
    gap: 15px;
}
.tabButtons>.activeTab {
    border-bottom: 2px solid blue;
    border-radius: 0px;
}

/* ... (other styles) */


.commentsTab {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* .chattHistoryTab {} */

.helpCancelIcons {
    display: flex;
    gap: 20px;
    padding-right: 5px;
}

/* *****************chatt History**************** */
.chattHistoryTab {
    display: flex;
    gap: 10px;
    flex-direction: column;

}

.historycircle>div:nth-child(1) {
    display: flex;
    gap: 10px;
    align-items: center;
}

.historycircle>div>h1 {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    background-color: #7B9EDD;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
}

.historyHeadings {
    display: flex;
    gap: 10px;
}

.historyHeadings>h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.2px;
    color: #7B9EDD;

}

.historyHeadings>h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    letter-spacing: 0.2px;
    color: #A1A1A1;
    background-color: yellow;
    padding: 1px 5px;

}

.writeComment>.Textfield {
    background-color: red;
}

/* ChattPanel.module.css */
.commentDivider {
    border: 1px solid #e0e0e0;
    margin: 10px 0;

}

.writeCommentContainer {
    display: flex;
    justify-content: center;
    min-height: 10px;
    border: none;
    padding: 0 10px;
}

.writeCommentContainer > button
{
    background-color: transparent;
    border: none;
    outline: none;
}
.writeCommentContainer>input {
    height: 40px;
    width: 100%;
    text-indent: 10px;
    background-color: #E2E2E2;
    border-radius: 4px;
    border: none;

}

.writeCommentContainer>input:focus {
    border: none;

}