.SelectorMain
{
    display: flex;
    height: 40px;
    border: 1px solid black;
    border-radius: 8px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 15;
    align-items: center;
    /* padding: 10px 15px; */
}

.SelectorMain > h2
{
    display: flex;
    padding: 10px 15px;
    width: 100%;

}

.BackDropStyle
{
    position: fixed;
    inset: 0;
    z-index: 18;
    width: 100%;
    height: 100%;
}
.SelectorDropDown
{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 250px;
    overflow-y: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 50;
    background-color: white;
}

.Searchbox
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px ;
   

}
.Searchbox > input
{
    width: 100%;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #d6d0d0;
    outline: none;
    text-indent: 10px;
}
.SelectorList
{
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

}

.SelectorList >li
{
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
}
.SelectorList >li:hover
{
    background-color: rgba(134, 134, 253, 0.158);
}