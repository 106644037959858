.proNavbar {
    max-width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky ;
    top: 0 ;
    z-index: 99999 ;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.proNavbar nav {
    width: 85%;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.proNavbar nav .proNavbarStart {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.proLogo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.proList ul {
    display: flex;
    gap: 60px;
    align-items: center;
    justify-content: center;
    list-style: none;
}

.proList ul li a{
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-weight: 500;

}
.proList ul li a:hover{
    color: #16469d;

}
.mobileMenuIcon ul li:hover{
    color: #16469d;

}

.proButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.proButton p {
    color: #16469d;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.proButton button {
    background-color: #16469d;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 30px;
    text-transform: capitalize;
    width: max-content;
}

/* -------------------dropdown-------------- */
.proDropDown {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    height: 100%;
    display: flex;
}

.proDropDown p {
    display: flex;
    align-items: center;
}
.proDropDown p a{
    display: flex;
    align-items: center;


}
.proDropDown p span {
    display: flex;
    align-items: center;
    justify-content: center;


}
.proDropDown>ul {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    list-style: none;
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}
.proDropDown ul li {
    width: 100%;
    text-align: start;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 15px;
    
}

.proDropDown ul li:hover {
    background: #f0f0f0;
    border-radius: 8px;
}

.proDropDown.active ul {
    display: block;
}

/* ------------------------ */
.mobileMenuIcon {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    height: 100%;
    display: flex;
}

.mobileMenuIcon p {
    display: flex;
    align-items: center;


}
.mobileMenuIcon p span {
    display: flex;
    align-items: center;
    justify-content: center;


}
.mobileMenuIcon>ul {
    position: absolute;
    top: 110%;
    left: 50;
    right: 0;
    list-style: none;
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}
.mobileMenuIcon ul li {
    width: 100%;
    text-align: start;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 15px;
    
    
}
.mobileMenuIcon ul li a{
   color: #000;
    
    
}
.mobileMenuIcon ul li a:hover{
   color: #16469d;
    
    
}
.mobileMenuIcon ul button {
    background-color: #16469d;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border-radius: 30px;
    text-transform: capitalize;
    width: max-content;
    margin-left: 10px;
    margin-bottom: 10px;
    
}

.mobileMenuIcon ul li:hover {
    background: #f0f0f0;
    border-radius: 8px;
}

.mobileMenuIcon.active ul {
    display: block;
}
.mobileMenuIcon{
    display: none;
}

@media screen and (max-width:876px) {
    .proNavbar{
        height: 80px;
    }
    .proNavbar nav .proList{
        display: none;
    }
    .proNavbar nav .proButton{
        display: none;
    }
    .mobileMenuIcon{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
    
    }
    .proLogo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
 
}
