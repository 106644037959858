.modalContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 500px; */
    background-color: white;
    box-shadow: 24;
    padding: 30px 30px;
    border-radius: 4px;
    width: 25vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90vh;
    overflow: scroll;
    

}

.modalContainer::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}

.contentWrapper
{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalHeader
{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

}
.modalHeader>h3
{
    font-size: 20px;
}
.modalHeader>span
{
   width: 30px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;

}
.modalHeader>span>svg
{
    width: 100%;
    height: 100%;
}


.formContainer
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
   
}



.formCol {
    flex: 1;
    padding: 0 15px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}



.halfRow {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

@media screen and (max-width:900px) {
    .formCol {
        order: 2;
    }
    .formWrapper
    {
        flex-direction: column;
        gap: 20px;
    }
   

    .imgCont {
        display: flex;
        justify-content: center;
        order: 1;
    }
    .halfRow {
        flex-direction: column;
    }
}
.formRow {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    width: 100%;
}

.formRow>span {
    position: absolute;
    top: 100%;
    color: rgb(151, 5, 5);
    font-size: 10px;

}

.formRow>label {
    font-size: 14px;
    color: #353535;

}

.formRow>label>span {
    color: red
}

.formRow>input,
.formRow>select {
    height: 40px;
    width: 100%;
    outline: none;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 0 10px;
    color: black;
    font-size: 16px;

}
.formRow>textarea
 {
    min-height: 150px;
    height: 150px;
    width: 100%;
    outline: none;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 16px;
    resize: vertical;
    color: black;

}

.formRow>input:focus,
.formRow>textarea:focus,
.formRow>select:focus
 {
    border: 1px solid #353535;
}


.formBtn
{
   
    display: flex;
    width: 100%;
    justify-content: center;
    /* padding: 0 15px; */
    
}

.formBtn>button
{
    width: 100%;
    height: 40px;
    background-color: #16469d;
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 16px;
}
