
.specficationTabMain {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    gap: 20px;

}

.specficationTable
{
    width: 100%;
    border: 1px solid #d2d2d2;
    border-collapse: collapse;
}
.tableHeader
{
    height: 40px;
    background-color: #F6F6F6;
    border: 1px solid #d2d2d2;
}
.tableHeader>th
{
    text-align: left;
    padding: 0 15px;
}

.tableRow
{
    min-height: 50px;
    border-bottom: 1px solid #d2d2d2;
}

.reactQuillTd>div>div
{
border: none !important;
}

.vendorInfo
{
    padding: 0 15px;
    width: 20%;
}

.descriptionCompareTextBox
{
    padding:10px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    height: 100px;
    overflow: auto;
    width: 100%;
}

.descriptionCompareTextBox:focus
{
    outline: none;

}


.specficationTable
{
    width: 100%;
    border: 1px solid #d2d2d2;
    border-collapse: collapse;
}
.tableHeader
{
    height: 40px;
    background-color: #F6F6F6;
    border: 1px solid #d2d2d2;
}
.tableHeader>th
{
    text-align: left;
    padding: 0 15px;
}

.tableRow
{
    min-height: 50px;
    border-bottom: 1px solid #d2d2d2;
}

.reactQuillTd>div>div
{
border: none !important;
}

.vendorInfo
{
    padding: 0 15px;
    width: 20%;
}