/* .signupMain
{
display: flex;
flex-direction: column;
width: 100%;
height: 100vh;
} */

.OTPVerifyColumn
{
    display: flex;
flex-direction: column;
width: 100%;
height: 100vh;

}
.OtpVerifyForm
{
    width: 50%;
    padding: 40px;
}

.slider
{
    width: 50%;
}