.whiteBackDrop {

    position: fixed;
    inset: 0;
    background-color: rgba(128, 128, 128, 0.358);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.MainSubClass {
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 600px;
    height: 50vh;
    position: absolute;
    top: 30%;
    z-index: 1000;
    left: 33%;
    background-color: white;
    /* border: 1px solid grey; */
    padding: 15px 0px;
}

.MainSubClass>img {
    display: flex;
    justify-content: center;
    width: 28%;
    margin-top: -10px;
}

.MainSubClass>h1 {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    color: #000000;
    letter-spacing: 0.2px;
    margin-top: -30px;
}

.MainSubClass>p {
    display: flex;
    justify-content: center;
    width: 68%;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.58px;
    color: #000000;
    letter-spacing: 0.2px;
}

.ButtonSection {
    display: flex;
    gap: 20px;
}

.ButtonSection>button {
    text-transform: capitalize;
}

.ButtonSection>button:hover {
    border: none;

}

@media (max-width:1280px) {
    .MainSubClass {
        left: 25%;
    }
}
@media (max-width:1024px) {
    .MainSubClass {
        left: 20%;
    }
}