.Firstclass{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    
}
.secondMainClass{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

Image {
    cursor: pointer;
}

.secondMainText{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.secondMainText h1{
    width: 80%;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
}
.buttonFirst{
    width: 10%;
}

.buttonFirst, .buttonSecond {
    text-transform: capitalize !important;
}

.buttonFirst {
    background-color: #16469D !important;
}

.buttonSecond {
    color: #16469D !important;
}

.buttonFirst:hover {
    background-color: #1d54b9 !important;
}

@media(max-width:451px){
    .secondMainClass img {
        width: 90%;
        height: 100%;
    }
}