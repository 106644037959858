.mainPage {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1692425810/Group_427319420_1_nllizv.png');
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    padding: 10px;

}

.container {
    display: flex;
    flex-direction: column;
   
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    /* height: 550px; */
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 20px 20px 50px 2px grey;
    gap: 10px;
    padding: 30px 20px;
    
}

.companyLogo {
    display: flex;
    justify-content: center;
   width: 100%;
   align-items: center;
    overflow: hidden;
    /* height: 55px; */
    max-width: 300px;
}
.companyLogo img
{
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.welcome
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
}
.welcome h3
{
    font-size: 16px;
    font-weight: 600;
}
.VisibleVendorPass{
    position: absolute;
    bottom: 2px;
    right: 10px;
}