.dashboardMain {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.dashboardHeader {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

}

.dashboardHeaderImg {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E57373;
    border-radius: 50%;
    font-size: 30px;
    font-weight: 500;
}

.dashboardHeaderText {
    font-size: 30px;
    font-weight: 300;
}

.dashboardHeaderText>span {
    font-weight: 500;
}

.dashboardCards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    overflow-y: auto;
}

.dashboardCard {
    width: max(45%, 400px);
    flex-grow: 1;
    background-color: white;
    height: 350px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    padding: 10px;
    min-width: 350px;
}

@media screen and (max-width:768px) {
    .dashboardCards {

        padding: 0px;

    }

}


.dashboardCardCont
{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    flex-grow: 1;
    overflow-y: auto;
   

}


.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.10);


}

.header .headerContent {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
}

.headerContent h6 {
    color: #333;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px
}

.header .headerIcons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
}

.cardData h6 {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cardData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.10);
}

.workData {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}