*
{
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.tableWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    padding-bottom: 100px;
    /* padding: 20px; */
}

.attributeTable {
    width: 100%;
     border-collapse: collapse;
    border: 1px solid #D2D2D2;
    border-radius:  8px;
    
}

.tableHeader {
    height: 35px;
    background-color: #FAFAFA;
    position: sticky;
    top:0;
    left: 0;
    outline: 1px solid #d2d2d2;
    z-index: 2;
}
.tableHeader>th
{
    height: 35px;
    max-height: 35px;
    border: 1px solid #d2d2d2;
    border-bottom: none;
    position: relative;
    min-width: 160px;
   
}
.tableHeader>th>div
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 12px;
}
.tableHeader>th>div>p
{

    font-size: 12px;
    font-weight: 400;
    color: #141313;
   

}
.tableHeader>th>p 
{
    width: 100%;
    height: 100%;
   padding-left: 12px;
   padding-top: 10px;
    
    position: relative;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: #141313;
   

}

.moreWrapper {
    width: 20px;
    height: 20px;
   
    display: flex;

    justify-content: center;
    align-content: center;
    z-index: 50;


}

.moreWrapper>span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;

}

.moreWrapper>span>svg {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.addIcon
{
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addIcon>svg
{
    width: 100%;
    height: 100%;
    stroke-width: 3px;
}


.tableBody
{
height: 49px;

}


.tableData
{
    height: 40px;
    border: 1px solid #d2d2d2;
    position: relative;
}
.tableDataDuplicate
{
    outline: 1px solid red;
}
.tableData>td
{
    height: 40px;
    border: 1px solid #d2d2d2;
    position: relative;
}
/* .tableData:nth-child(1)>td
{
    border-top: none;
} */

.tableData>td>p
{
    width: 100%;
    height: 100%;
   padding-left: 12px;
   padding-top: 12px;
   padding-right: 12px;
    
    position: relative;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #141313;

}
.tableData>td>input
{
    width: 100%;
    height: 100%;
    padding-left: 12px;
    /* padding-top: 0px; */
    position: relative;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #141313;
    border: none;
    outline: none;
    background-color: transparent;
}
.tableData>td>textarea
{
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-top: 10px;
    min-width: 100%;
    min-height: 2lh;
    max-height: 2lh;
    position: relative;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #141313;
    border: none;
    outline: none;
   
    background-color: transparent;
}
.tableData>td>div
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.attributeValue
{
    width: 90% !important;
    height: 30px !important;
    border: 1px solid #B6B6B6 !important;
    border-radius: 4px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #141313;
    text-align: center;
}


.descriptionCompareTextBox
{
    padding:10px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    height: 100px;
    overflow: auto;
    width: 100%;
}

.descriptionCompareTextBox:focus
{
    outline: none;

}

.specficationTable
{
    width: 100%;
    border: 1px solid #d2d2d2;
    border-collapse: collapse;
}
.tableHeader
{
    height: 40px;
    background-color: #F6F6F6;
    border: 1px solid #d2d2d2;
}
.tableHeader>th
{
    text-align: left;
    padding: 0 15px;
}

.tableRow
{
    min-height: 50px;
    border-bottom: 1px solid #d2d2d2;
}

.reactQuillTd>div>div
{
border: none !important;
}

.vendorInfo
{
    padding: 0 15px;
    width: 20%;
}