.templatesWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
}

.templateCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* border: 1px solid #ccc; */
}

.templateCard>p {
    font-size: 16px;
    font-weight: 600;
}

.templateCard>input {
    width: 18px;
    height: 18px;
}


.templatesWrapper::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.templatesWrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.templatesWrapper::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.templatesWrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}