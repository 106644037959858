.modalContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 500px; */
    background-color: white;
    box-shadow: 24;
    padding: 30px 30px;
    border-radius: 4px;
    width: 25vw;
    min-width: 429px;
    animation: slideUp 0.3s forwards;
    outline: none;
    min-height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;

}

.modalContainer::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    70% {
        top: 45%;
    }

    100% {
        top: 50%;
    }
}

.modalBtn
{
    width: 80px;
    height: 35px;
    border: none;
    border-radius: 4px;
  
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.pimaryModalBtn
{
    background-color: #16469D;
    color: #FFFFFF;
}

.secondaryModalBtn
{
    background-color: transparent;
    color: #2F2E2E;
}

.formContainer
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
}

.modalHeader
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.modalHeader>h3
{
    font-size: 24px;
    font-weight: 400;
    color: #1F1F1F;
}
.modalHeader>span
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.modalHeader>span>svg
{
    width:100%;
    height: 100%;
    font-weight: 500;
}







.formGroup
{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    position: relative;
}
.formGroup>label
{
   font-size: 14px;
   font-weight: 400;
   color: #1F1F1F;
   line-height: 25px;
}
.formGroup>span
{
   position: absolute;
   font-size: 10px;
   color: rgb(202, 8, 8);
   top: 105%;
}

.formGroup>input[type="text"],.formGroup>select
{
    width: 100%;
    height: 40px;
    border: 1px solid #747775;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 30px;
    outline: none;

}

.formGroup>input[type="text"]:focus, .formGroup>select:focus
{
    border: 1px solid #1F1F1F;
}
